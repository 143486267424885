import { requestApi,requestRaw } from '../util/network'

//登录
export const getlogin = (data) => requestApi('/Videodiy.User/login','POST',data)
	// requestApi('/Videodiy.User/login','POST',data,'member')

//获取验证码
export const getCaptcha = (data) => requestRaw('/Base/captcha','GET',data)

export const getSmsSend = (data) => requestApi('/Videodiy.User/sms_send','GET',data)

//注册
export const setRegister = (data) => requestApi('/Videodiy.User/add','POST',data)

//用户资料
export const getUserinfo = (data) => requestApi('/Videodiy.User/userinfo','GET',data,'member')

//重新设置密码
export const resetPassword = (data) => requestApi('/Videodiy.User/password','POST',data,'member')

//余额充值
export const setOfficereCharge = (data) => requestApi('/Wxpay/officereCharge','get',data,'member')

//余额充值
export const getUserczView = (data) => requestApi('/Videodiy.Usercz/view','get',data,'member')

//余额列表
export const getUserczIndex = (data) => requestApi('/Videodiy.Usercz/index','get',data,'member')

//余额充值
export const setUserczAdd = (data) => requestApi('/Videodiy.Usercz/add','POST',data,'member')

//快捷充值
export const getRechargeList = (data) => requestApi('/Videodiy.Recharge/index','get',data,'member')

//上传图片
export const toUpload = (data) => requestApi('/Base/upload','POST',data,'member')

//上传图片头像
export const toUploadUserinfo = (data) => requestApi('/Videodiy.User/update_userinfo','POST',data,'member')

//上传素材
export const toUploadAssets = (data) => requestApi('/Base/assets','POST',data,'member')

//声音列表
export const getVoices = (data) => requestApi('/Videodiy.Voices/index','GET',data,'member')

//演员列表
export const getAvatars = (data) => requestApi('/Videodiy.Avatars/index','GET',data,'member')

//视频列表
export const getUservideo = (data) => requestApi('/Videodiy.Uservideo/index','GET',data,'member')

//视频详细
export const getUservideoInfo = (data) => requestApi('/Videodiy.Uservideo/view','GET',data,'member')

//视频添加
export const getUservideoAdd = (data) => requestApi('/Videodiy.Uservideo/add','POST',data,'member')

//视频修改
export const getUservideoUpdate = (data) => requestApi('/Videodiy.Uservideo/update','POST',data,'member')

//视频修改
export const getCopydata = (data) => requestApi('/Videodiy.Uservideo/copydata','POST',data,'member')

//视频删除
export const getUservideoDelete = (data) => requestApi('/Videodiy.Uservideo/delete','POST',data,'member')

//用户素材
export const getXxxfile = (data) => requestApi('/Videodiy.Xxxfile/index','GET',data,'member')

//素材详细
export const getXxxview = (data) => requestApi('/Videodiy.Xxxfile/view','GET',data,'member')

//删除用户素材
export const setXxxfileDelete = (data) => requestApi('/Videodiy.Xxxfile/delete','POST',data,'member')

//购买套餐
export const getViptime = (data) => requestApi('/Videodiy.Viptime/index','GET',data,'member')

//开通套餐
export const setViptimeKaiVip = (data) => requestApi('/Videodiy.Viptime/kai_vip','POST',data,'member')

//是否开启套餐
export const getViptimeConfig = (data) => requestApi('/Videodiy/config','GET',data,'member')

//判断语言
export const getmiuseConfig = (data) => requestApi('/Videodiy.Uservideo/text','GET',data,'member')

//声音列表v
export const getvoiceConfig = (data) => requestApi('/Videodiy.Voices/index','GET',data,'member')

//上传草稿
export const getvoiceadd = (data) => requestApi('/Videodiy.Uservideo/add','POST',data,'member')

//背景素材
export const getvoicebj = (data) => requestApi('/Videodiy.Assets/index','get',data,'member')

//生成视频
export const getuplodebj = (data) => requestApi('/Videodiy.Uservideo/videosc','POST',data,'member')

//背景音乐
export const getbjMiuse = (data) => requestApi('/Videodiy.Soundtrack/index','GET',data,'member')
