<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #ddd;">
			<el-col :span="12">
				<div class="material">
					素材列表
				</div>
			</el-col>
			<el-col :span="12">
				<div class="but">
					 <el-button @click="showVisible = true" type="primary">添加素材</el-button>
				</div>
			</el-col>
		</el-header>
		<el-main>
			  <el-table
				v-loading="loading"
			    :data="tableData"
				:row-style="{height:'80px'}"
			    style="width: 100%">
			    <el-table-column label="图片" width="80">
			      <template slot-scope="scope">
					  <div v-if="scope.row.filetype === 'video/mp4'">
						<video width="60" height="60" loop="loop" autoplay="autoplay" muted="muted">
							<source :src="scope.row.filepath" type="video/mp4"></source>
							当前浏览器不支持 video直接播放
						</video>
					  </div>
					  <div v-else>
							<el-image :src="scope.row.filepath" :lazy="true" width="60" style="max-height: 60px;">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>	
							</el-image>
					  </div>
			      </template>
			    </el-table-column>
			    <el-table-column label="文件类型" width="160">
			      <template slot-scope="scope">
			          <div>{{ scope.row.filetype }}</div>			    
			      </template>
			    </el-table-column>
			    <el-table-column label="文件状态" width="100">
			      <template slot-scope="scope">
					  <el-tag type="success" v-if="scope.row.status == 1">启&nbsp;用</el-tag>
					  <el-tag type="warning" v-if="scope.row.status == 0">转码中</el-tag>
			      </template>
			    </el-table-column>
			    <el-table-column label="添加时间" >
			      <template slot-scope="scope">
					  <div>{{ formatDate(scope.row.create_time,true) }}</div>
			      </template>
			    </el-table-column>
				<el-table-column
					prop="fileid"
					label="文件编号"
					>
				</el-table-column>
			    <el-table-column label="操作" width="180" fixed="right">
			      <template slot-scope="scope">
			        <el-button
			          size="mini"
					  type="success"
			          @click="handleShow(scope.$index, scope.row)">查看</el-button>
	
			        <el-button
			          size="mini"
			          type="danger"
			          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
			      </template>
			    </el-table-column>
			  </el-table>
				<div class="pagination">
				    <div class="block">
				      <el-pagination
				        @size-change="handleSizeChange"
				        @current-change="handleCurrentChange"
				        :current-page.sync="form.page"
				        :page-sizes="[10,20,50,100, 200]"
				        :page-size="form.limit"
				        layout="total, sizes, prev, pager, next, jumper"
				        :total="total">
				      </el-pagination>
				    </div>
				</div>
				
				<el-dialog
				  title="素材查看"
				  :visible.sync="dialogVisible"
				  width="50%"
				  :before-close="handleClose">
				  <div class="content">
					   <div v-if="list && list.sts == 2">
						  <el-image :src="list.src" style="width: 100%; height: 100%;">
							  <div slot="error" class="image-slot">
								<i class="el-icon-picture-outline"></i>
							  </div>
						  </el-image>
					  </div>
					  <div v-if="list && list.sts == 1">
						  <video ref="videoid" controls="" width="100%" height="400px" loop="loop" autoplay="autoplay" muted="muted">
						  	<source :src="list.src" type="video/mp4"></source>
						  	当前浏览器不支持 video直接播放
						  </video>
					  </div>
					  
				  </div>
				</el-dialog>
				
				<el-dialog
					title="素材上传"
					:visible.sync="showVisible"
					width="40%"
					:before-close="handleClose">
					<div class="content" style="text-align: center;">
						<!--  -->
						<el-upload
						  class="upload-demo"
						  action="/api/Base/assets"
						  :headers="importHeaders"
						  accept="image/jpeg, image/png, image/svg+xml, video/mp4, video/webm"
							:on-success="assetssuccess"
							:before-upload="beforeAvatarUpload"
						  :file-list="fileList">
						  <el-button size="small" type="primary">点击上传</el-button>
						  <div slot="tip" class="el-upload__tip">只能上传jpg/png/svg+xml/mp4/webm文件，且不超过100MB</div>
						</el-upload>
					</div>
				</el-dialog>
		</el-main>
	</el-container>
</template>

<script>
	import { getXxxfile,setXxxfileDelete,getXxxview } from '@/api/member.js';
	import { formatDate } from '@/util/commons'
	import { mapState, mapMutations, mapActions } from 'vuex'
    export default {
        name: "materialList",
		head: {
		    title: "素材列表",
		},
		computed: {
		  ...mapState({
		    user: state => state.member.storeInfo,
		    token: state => state.member.token,
		  })
		},
		data() {
			return {
				dialogVisible:false,
				showVisible:false,
				loading:false,
				form:{
					limit:10,
					page:0,
					filetype:'',
					fileid:'',
					status:'',
					create_time_start:'',
					create_time_end:'',
				},
				total:0,
				tableData: [],
				list:{
					sts:0,
					src:''
				},
				importHeaders:{
					Authorization:'',
				},
				fileList:[]
			}
		},
		created() {
			this.importHeaders.Authorization = this.token
		},
		mounted() {
			this.getXxxfileList()
		},
		methods: {
			formatDate,
			handleClose(done){
				if(this.list.sts == 1){
					this.$refs.videoid.currentTime = 0
					this.$refs.videoid.pause()
				}
				done();
			},
			beforeAvatarUpload(file) {
				const isLt100M = file.size / 1024 / 1024 < 100;
		
				if (!isLt100M) {
				  this.$message.error('上传视频不能超过 100MB 及 图片大小 不能超过 5MB!');
				}
				
				return isLt100M;
			},
			assetssuccess(){
				this.showVisible = false
				this.getXxxfileList()
			},
			handleShow(row,data){
				if(data.filetype == 'video/mp4'){
					this.list.sts = 1
				}else if(data.filetype == 'image/jpeg' || data.filetype == 'image/png'){
					this.list.sts = 2
				}
				
				this.list.src = data.filepath
				console.log(this.list)
				this.$nextTick(()=>{
					this.dialogVisible =true
					if(data.filetype == 'video/mp4'){
						if(this.$refs.videoid){
							this.$refs.videoid.play()
						}
					}
				})
			},
			handleSizeChange(val) {
				this.form.page = 1 
				this.form.limit = val
				this.getXxxfileList()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.form.page = val
				this.getXxxfileList()
				console.log(`当前页: ${val}`);
			},
			getXxxfileList:function(){
				this.loading = true
				getXxxfile(this.form).then(res=>{
					console.log(res)
					this.loading = false
					this.tableData = res.data.list
					this.total = res.data.count
				}).catch(err=>{
					this.loading = false
					this.$message.error(err.msg);
					console.log(err)
				})
			},
			handleDelete:function(row,data){
				console.log(row,data)
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					setXxxfileDelete({ids:data.id}).then(res=>{
						this.$message.success(res.msg);
						this.getXxxfileList()
					}).catch(err=>{
						this.$message.error(err.msg);
					})
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '已取消删除'
				  });          
				});
			}
		},
    }
</script>

<style lang="scss" scoped>
.material {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
}
.but {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}
.el-table .cell img {
    display: block;
}
.pagination {
    margin-top: 16px;
    text-align: center;
}
</style>
