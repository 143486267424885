import { getUserinfo } from '@/api/member.js';

const state = {
    token: null,
	tokenTime:null,
	storeInfo:{}
}
const mutations = {
  saveStoreInfo (state, value) {
    state.storeInfo = value
  },
  saveToken (state, value) {
    state.token = value
  },
  saveTokenTime (state, value) {
    state.tokenTime = value
  }
}

const actions ={
	SaveStoreInfo ({ commit, state }) {
	  return new Promise((resolve, reject) => {
	    getUserinfo().then((response) => {
	      if (response.data) {
	        commit('saveStoreInfo', response.data)
	      }
	      resolve(response)
	    }, (error) => {
	      reject(error)
	    })
	  })
	}
}

export  default  {
    state,
	mutations,
	actions
}
