<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #ddd;">
			<el-col :span="12">
				<div class="video">
					首页控制台
				</div>
			</el-col>
			<el-col :span="12">
 				<div class="videobut">
					 <el-button type="primary" @click="newViode">创建新视频</el-button>
				</div>
			</el-col>
		</el-header>
		<el-main>
			<div id="top">
				<div class="title">
					最新人物
				</div>
				<div class="list">
					<el-row>
						<el-col :span="4" v-for="(item,index) in AvatarsInfo" :key="index">
							<div class="img word-flex">
								<el-image :src="item.pic" :lazy="true" width="100%">
									<div slot="error" class="image-slot">
										<i class="el-icon-picture-outline"></i>
									</div>
								</el-image>
							</div>
							<div class="h2">
								{{item.name}}
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div id="bottom">
				<div class="title">
					最新视频
				</div>
				<div class="list">
					<div v-for="(item,index) in videoInfo" :key="index" class="line word-flex word-flex-between">
						<div class="left-list word-flex word-flex-between">
							<div class="img">
								<div class="img-background" :style="{backgroundImage:'url('+item.background+')',backgroundSize: 'cover',backgroundPosition: 'center center',backgroundRepeat: 'no-repeat'}" style="display: inline-flex;">
									<el-image :src="item.pic" :lazy="true" width="100%">
										<div slot="error" class="image-slot">
											<i class="el-icon-picture-outline"></i>
										</div>
									</el-image>
								</div>
								<div class="jss596" v-if="item.background">
									<svg width="7" height="50" viewBox="0 0 7 50" fill="none">
										<path fill-rule="evenodd" clip-rule="evenodd" d="M0.0737374 49.8776C4.16521 47.966 7.00008 43.814 7.00008 38.9998V10.9998C7.00008 6.18572 4.16525 2.03371 0.0738243 0.12207C2.48589 2.31702 3.99994 5.4816 3.99994 8.99981V40.9998C3.99994 44.5181 2.48586 47.6827 0.0737374 49.8776Z" fill="#D1D7DD"></path>
									</svg>
									<svg width="5" height="44" viewBox="0 0 5 44" fill="none" style="margin-left: -0.5px;"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.425292 43.4278C3.21146 41.2303 4.99999 37.8242 4.99999 34.0004V10.0004C4.99999 6.17726 3.21217 2.77173 0.426959 0.574218C2.03829 2.61703 2.99985 5.1962 2.99985 7.99996V36C2.99985 38.8047 2.03763 41.3847 0.425292 43.4278Z" fill="#EFF1F3"></path>
									</svg>
								</div>
							</div>
							<div class="list-title">
								<div class="name">{{item.title}}</div>
							</div>
						</div>
						<div class="date">
							<span class="download" v-if="item.download_url">
								<el-button type="primary" icon="el-icon-download" size="small" @click="down(item)" >视频下载</el-button>
							</span>
							<span>{{ formatDate(item.add_time,true)}}</span>
						</div>
					</div>
				</div>
			</div>
<!-- 			 -->
		</el-main>
	</el-container>
</template>

<script>
	import { getUservideo,getAvatars,getUservideoInfo } from '@/api/member.js';
	import { formatDate } from '@/util/commons'
    export default {
        name: "Index",
		head: {
		    title: "首页",
		},
		data() {
			return {
				videoInfo:[],
				AvatarsInfo:[],
				form:{
					limit:6,
					page:1
				}
			}
		},
		created() {
			this.getAvatars()
			this.getvideoInfo()
		},
		mounted() {

		},
		methods: {
			formatDate,
			newViode(){
				this.$router.push('/videoOperate')
			},
			down(data){
				if (!data.download_url) {
					return false
				}
				this.$confirm('此操作将下载该文件, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					getUservideoInfo({id:data.id}).then(res=>{
						this.$message.success(res.msg);
						window.open(res.data.download_url)
					}).catch(err=>{
						this.$message.error(err.msg);
					})
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '已取消下载'
				  });
				});
			},
			getAvatars(){
				this.form.status = 2
				getAvatars(this.form).then(res=>{
					console.log(res)
					this.AvatarsInfo = res.data.list
				}).catch(err=>{
					console.log(err.msg)
				})
			},
			getvideoInfo(){
				getUservideo(this.form).then(res=>{
					console.log(res)
					this.videoInfo = res.data.list
				}).catch(err=>{
					console.log(err.msg)
				})
			}
		}
    }
</script>

<style lang="scss" scoped>
.video {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
}
.videobut {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}
#top {
    border: 1px solid #ddd;
    padding: 22px 12px;
	position: relative;
	border-radius: 8px;
	margin-top: 30px;
	.title {
	    position: absolute;
	    top: -10px;
	    background: #fff;
	    padding: 0 16px;
	}
	.list .h2 {
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    overflow: hidden;
	    margin-top: 10px;
	    padding: 0 12px;
	}
	.img {
	    border-radius: 8px;
	    background: #F0F3F7;
	}
	.list .el-col {
	    padding: 8px;
	}
}
#bottom {
    margin-top: 30px;
    margin-bottom: 40px;
    .title{
		padding: 12px 28px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 18px;
	}
	.list{
		.line {
		    padding: 2px 20px;
			min-height: 80px;
		    border-bottom: 1px solid #ddd;
		}
		.download {
		    margin-right: 34px;
		}
		.left-list{
			.img {
				width: 80px;
				margin-right: 30px;
				position: relative;
				text-align: center;
				.jss596 {
				    top: 0;
				    right: 3px;
				    height: 100%;
				    display: flex;
				    position: absolute;
				    align-items: center;
				    flex-direction: row;
					// svg {
					//     filter: drop-shadow(20px 0 0 #C4C6C6);
					//     position: relative;
					//     left: -21px;
					//     z-index: -1;
					// }
				}
				.img-background {
				    transform: scale(0.65);
				    border-radius: 12px;
				}
			}
		}
	}
}
</style>
