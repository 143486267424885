export default {
	aclass:null,
	bclass:null,
	laclass:null,
	lbclass:null,
	awidth:'',
	aheight:'',
	bwidth:'',
	bheight:'',
	init (aclass,baclass){
		if(aclass){
			this.aclass = aclass
			this.awidth = aclass.offsetWidth
			this.aheight = aclass.offsetHeight
		}
		if(baclass){
			this.bclass = baclass
			this.bwidth = baclass.offsetWidth
			this.bheight = baclass.offsetHeight
			// console.log(this.awidth,this.aheight,this.bwidth,this.bheight)
		}
		
	},
	// 居中初始化
	amarginCon(aclass,baclass,margina){

		aclass.style.left = '0px'
		aclass.style.bottom = '0px'
		aclass.style.right = '0px'
		aclass.style.margin = "0 auto";
		aclass.style.height = margina+'%';

		baclass.style.left = '0px'
		baclass.style.bottom = '0px'
		baclass.style.right = '0px'
		baclass.style.margin = "0 auto";
		baclass.style.height = margina+'%';
	},
	rightBut(aclass,bclass,size){
		console.log(bclass)
	
		aclass.style.left = 'unset'
		aclass.style.right = '5px'
		aclass.style.margin = 'unset';
		aclass.style.height= size+'%';
		
		bclass.style.margin = 'unset';
		bclass.style.left = 'unset'
		bclass.style.right = '5px'
		bclass.style.height= size+'%';
	},
	leftBtn(aclass,bclass,size){
		console.log(bclass)

		aclass.style.left = '5px'
		aclass.style.right = 'unset'
		aclass.style.margin = 'unset';
		aclass.style.height= size+'%';
		
		bclass.style.margin = 'unset';
		bclass.style.left = '5px'
		bclass.style.right = 'unset'
		bclass.style.height= size+'%';
	},
	// 滚动值
	Slidingsize(laclass,lbclass,size){
		if(laclass){
			laclass.style.height = size+'%';
			// console.log(lbclass)
			lbclass.style.height = size+'%';
		}
	},
	// 头像模式
	headPortrait(laclass,lbclass,size,color){
		if(laclass){
			laclass.style.top = 'calc(100% - 50% + 2px)';
			laclass.style.transform = 'translateY(-50%)';
			laclass.style.border = '1px solid #ddd';
			laclass.style.margin = '0 auto';
			laclass.style.left = '0'
			laclass.style.right = '0'
			laclass.style.bottom = '0'
			laclass.style.height= 'calc('+size+'% - 6px)';
			laclass.style.borderRadius = '100%'
			laclass.style.background = color
			
			lbclass.style.top = 'calc(100% - 50% + 2px)';
			lbclass.style.transform = 'translateY(-50%)';
			lbclass.style.border = '1px solid #ddd';
			lbclass.style.margin = '0 auto';
			lbclass.style.left = '0'
			lbclass.style.right = '0'
			lbclass.style.bottom = '0'
			lbclass.style.borderRadius = '100%'
			lbclass.style.height= 'calc('+size+'% - 6px)';
			lbclass.style.background = color
			
		}
	},
	// 头像模式
	headPortraitClear(laclass,lbclass,size){
		if(laclass){
			laclass.style.top = 'unset';
			laclass.style.transform = 'unset';
			laclass.style.border = 'unset';
			laclass.style.margin = '0px auto';
			laclass.style.left = '0'
			laclass.style.right = '0'
			laclass.style.bottom = '0'
			laclass.style.height= '100%';
			laclass.style.borderRadius = 'unset'
			laclass.style.background = 'unset'
			
			lbclass.style.top = 'unset';
			lbclass.style.transform = 'unset';
			lbclass.style.border = 'unset';
			lbclass.style.margin = '0 auto';
			lbclass.style.left = '0'
			lbclass.style.right = '0'
			lbclass.style.bottom = '0'
			lbclass.style.borderRadius = 'unset'
			lbclass.style.height= '100%';
			lbclass.style.background = 'unset'
			
		}
	}
	
}