<template>
	<div id="app" style="height: 100%">
		<router-view/>
	</div>
</template>

<script>
export default {
	name: 'App',
	head: {
		titleTemplate(title) {
			title = typeof title === "function" ? title(this.$store) : title;
			return title ? `${title} - 视频创作中心` : '视频创作中心';
		}
	},
	data(){
		return {
		}
	}
}
</script>

<style lang="scss">
  body{
    margin: 0;
	padding: 0;
	  outline: none;
  }
  .el-tabs__item{
    height: 60px;
    color: white !important;
    text-align: center;
    padding-top: 20px;
    font-size: 15px !important;
  }

</style>
