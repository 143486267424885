<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #ddd;">
			<el-col :span="12">
				<div class="video">
					视频详细
				</div>
			</el-col>
			<el-col :span="12">
				<div class="videobut">
					 <el-button @click="toback" type="primary">返回</el-button>
				</div>
			</el-col>
		</el-header>
		<el-main>
			<div class="content">
				<video width="100%"  controls="controls" :poster="videoInfo.pic">
					<source :src="videoInfo.download_url" type="video/mp4"></source>
					当前浏览器不支持 video直接播放
				</video>
				<!-- <el-image :src="videoInfo.pic"></el-image> -->
				<div class="xiazai word-flex word-flex-between">
					<div>
						{{videoInfo.title}}
					</div>
					<el-button @click="handleToDow(videoInfo)" type="primary">视频下载</el-button>
				</div>
			</div>
		</el-main>
	</el-container>
</template>

<script>
	import { getUservideoInfo } from '@/api/member.js';
	import { formatDate } from '@/util/commons'
    export default {
        name: "videoShow",
		head: {
		    title: "视频详细",
		},
		data() {
			return {
				videoInfo:{}
			}
		},
		created() {
			this.getUservideoInfo(this.$route.query.id)
		},
		mounted() {

		},
		methods: {
			formatDate,
			getUservideoInfo(id){
				getUservideoInfo({id:id}).then(res=>{
					console.log(res)
					this.videoInfo = res.data
				}).catch(err=>{
					console.log(err.msg)
				})
			},
			handleToDow(data){
				if (!data.download_url) {
					return false
				}
				window.open(data.download_url)
			},
			toback(){
				this.$router.push('/videoList')
			}
		}
    }
</script>

<style lang="scss" scoped>
.video {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
}
.videobut {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}
.content {
    max-width: 1000px;
    margin: auto;
	.xiazai {
	    margin-top: 22px;
	}
}
</style>
