<template>
<div class="login">
	<div class="login-con">
		<div class="img">
			<h1 v-if="formlogin === 1">视频创作中心</h1>
			<h1 v-else>视频创作中心注册</h1>
		</div>
		<div class="formlogin" v-show="formlogin === 1">
			<el-form ref="ruleForm" :model="ruleForm" class="demo-ruleForm">
				<el-form-item prop="username" :rules="[{ required: true, message: '用户名不能为空', trigger: 'blur'}]">
					<el-input type="text" prefix-icon="el-icon-user-solid" placeholder="请输入用户名/手机号码" v-model="ruleForm.username" autocomplete="off"></el-input>
				</el-form-item>

				<el-form-item prop="password" :rules="[{ required: true, message: '用户密码不能为空', trigger: 'blur'}]">
					<el-input type="password" show-password prefix-icon="el-icon-unlock" placeholder="请输入用户密码" v-model="ruleForm.password" autocomplete="off"></el-input>
				</el-form-item>
				
				<el-form-item class="buttop">
					<el-button type="primary" :loading="loadingbut" class="Submit" @click="onSubmit('ruleForm')">立即登录</el-button>
				</el-form-item>
				<div class="span">
					<span @click="onFormTo(2)">立即注册</span>
				</div>
			</el-form>
		</div>
		
		
		<div class="formlogin" v-show="formlogin===2">
			<el-form ref="ruleFormto" :rules="rules" :model="ruleFormto" >
				<el-form-item prop="nickname">
					<el-input prefix-icon="el-icon-user-solid" placeholder="请输入用户昵称" v-model="ruleFormto.nickname" autocomplete="off"></el-input>
				</el-form-item>
				
				<el-form-item prop="mobile">
					<el-input prefix-icon="el-icon-mobile-phone" maxlength="11" placeholder="请输入手机号码" v-model="ruleFormto.mobile" autocomplete="off"></el-input>
				</el-form-item>
				
				<el-form-item prop="verify">
					<el-col :span="15">
						<el-input prefix-icon="el-icon-s-check" maxlength="6" placeholder="请输入六位手机验证码" v-model="ruleFormto.verify" autocomplete="off"></el-input>
					</el-col>
					<el-col :span="9">
						<span class="codespan" @click="onCodeTo()">点击获取验证码</span>
					</el-col>
				</el-form-item>
		
				<el-form-item prop="password">
					<el-input type="password" show-password prefix-icon="el-icon-unlock" placeholder="请输入密码" v-model="ruleFormto.password" autocomplete="off"></el-input>
				</el-form-item>

				<el-form-item class="buttop">
					<el-button type="warning" :loading="loadingbut" class="Submit" @click="onRegister('ruleFormto')">点击注册</el-button>
				</el-form-item>
				<div class="span">
					<span @click="onFormTo(1)">立即登录</span>
				</div>
			</el-form>
		</div>
		
	</div>
</div>
</template>

<script>
	import { setRegister,getlogin,getSmsSend,getUserinfo } from '@/api/member.js';
	import { mapState, mapMutations, mapActions } from 'vuex'
    export default {
        name: "login",
		head: {
	        title: "登录",
	    },		
		data(){
			return {
				loadingbut:false,
				formlogin:1,
				ruleForm:{
					username:'',
					password:''
				},
				ruleFormto:{
					nickname:'',
					mobile:'',
					verify:'',
					verify_id:'',
					password:'',
					username:'',
					uipassword:''
				},
				poclick:false,
				img:'',
				rules:{
					nickname:[{ required: true, message: '用户昵称不能为空', trigger: 'blur'}],
					mobile:[{ required: true, message: '手机号码不能为空', trigger: 'blur'}],
					verify:[{ required: true, message: '验证码不能为空', trigger: 'blur'}],
					password:[{ required: true, message: '密码不能为空', trigger: 'blur'}],
				}
			};
		},
		computed: {
		  ...mapState({
		    user: state => state.member,
		  }),
		},
		created() {
			
			if( typeof(this.$route.query.clear) === 'string' || Number(this.$route.query.clear) === 1){
				this.setSaveToken(null);
				this.setSaveStoreInfo(null);
			}
			
			if (typeof (this.user.token) === 'string' || this.user.token) {
				this.$router.push({ name: 'index' })
			}
		},
		mounted() {
			this.ontoroute();
			if(this.$cookies.isKey('nav')){
				this.$cookies.remove('nav')
			}
			console.log(typeof(this.$route.query.clear),this.$route.query.clear)
		},
		watch:{
			'$route':function(val){
				this.ontoroute();
			}
		},
		methods:{
			...mapMutations({
			  setSaveToken: 'saveToken',setSaveStoreInfo:'saveStoreInfo',SetsaveTokenTime:'saveTokenTime'
			}),
			ontoroute(){
				if( typeof(this.$route.query.register) === 'string' || Number(this.$route.query.register) === 1){
					this.onFormTo(2)
				}else{
					this.onFormTo(1)
				}
			},
			onFormTo(val){
				this.formlogin = val
			},
			onCodeTo:function(){
				if(this.poclick){
					this.$message.error('验证码信息已发送，请勿重复点击');
					return false;
				}
				if(this.ruleFormto.mobile !== ''){
					getSmsSend({mobile:this.ruleFormto.mobile}).then(res=>{
						this.ruleFormto.verify_id = res.key
						this.poclick = true;
						var pokk = setTimeout(()=>{
							this.poclick = false;
						},120000)
						this.$message.success('发送成功，请在5分钟内输入验证码！');
					}).catch(err=>{
						this.$message.error(err.msg);
					})
				}else{
					this.$message.error('请先输入手机号码');
				}
			},
			onSubmit(formName){
				// this.setSaveToken('1111111')
				// this.$store.commit('saveToken','sdfsd123412312515')
					this.$refs[formName].validate((valid) => {
						  if (valid) {
							  this.loadingbut = true
							getlogin(this.ruleForm).then(res=>{
								this.setSaveToken(res.token);
								this.SetsaveTokenTime(Date.parse(new Date()));
								this.getUserInfo();
								this.$message({
									message:res.msg,
									type:"success",
									onClose:()=>{
										this.loadingbut = false
										this.$router.push({ name: 'index' })
									}
								})
							}).catch(err=>{
								console.log(err)
								this.loadingbut = false
								this.$message.error(err.msg);
							})
						  } else {
							console.log('error submit!!');
							return false;
						  }
					});
					
			},
			getUserInfo(){
				getUserinfo().then(res=>{
					this.setSaveStoreInfo(res.data)
				}).catch(err => {
					console.log(err)
				})
			},
			onRegister:function(formName){
				this.ruleFormto.username = this.ruleFormto.mobile;
				this.$refs[formName].validate((valid) => {
				          if (valid) {
							  this.loadingbut = true
							  this.poclick = false;
				            setRegister(this.ruleFormto).then(res=>{
								this.$message.success('注册成功');
								this.loadingbut = false
								this.formlogin = 1								
								this.ruleForm.username = '';
								this.ruleForm.password = '';
							}).catch(err=>{
								this.loadingbut = false
								this.$message.error(err.msg);
							})
				          } else {
				            console.log('error submit!!');
				            return false;
				          }
				        });
			}
		}
    }
</script>

<style scoped lang="scss">
.login {
    background: url("../assets/img/videobg.jpg");
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
	background-size: cover;
	background-position: center center;
	.login-con{
	    padding: 20px 50px 20px 50px;
	    background: #fff;
	    width: 330px;
	    border-radius: 5px;
	    text-align: center;
		box-shadow: 2px 2px 12px 0px #EEE;
		 .img {
		    margin-bottom: 40px;
		}
		.buttop {
		    margin-top: 30px;
		}
		.line {
		    margin: 12px 0;
		}
		.vcode {
		    width: 240px;
		}
		.Submit {
		    width: 100%;
		}
		.span span{
		    color: #515153;
			cursor: pointer;
		}
		.codespan{
			cursor: pointer;
		}
	}
}

</style>
