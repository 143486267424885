<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #ddd;">
			<el-col :span="12">
				<div class="datainfo">
					充值中心
				</div>
			</el-col>
			<el-col :span="12">
				<!-- <div class="but">
					 <el-button type="primary">添加素材</el-button>
				</div> -->
			</el-col>
		</el-header>
		<el-main>
			<el-form ref="form" :rules="rules" :model="form" label-width="100px">
				<div class="title">
					<h2>充值信息</h2>
				</div>
				
				<el-form-item label="VIP使用期:" v-if="user.vip_time && ViptimeConfig.vip_day_status == 1">
					<span v-if="user.vip_time*1000 > Date.parse(new Date())" style="color: #008040;">{{ formatDate(user.vip_time,true)}}</span>
					<span v-if="user.vip_time*1000 < Date.parse(new Date())" style="color: #FF0000;">{{ formatDate(user.vip_time,true)}}</span>
				</el-form-item>
				
				<el-form-item label="用户余额:">
					<span>{{user.now_money}}</span>
				</el-form-item>
				
				<el-form-item label="快捷充值:">
					<span class="RechargeList" v-for="(item,index) in RechargeList" :key="index">
						<el-button @click="onRecharge(item.amount)">{{item.amount}}元</el-button>
					</span>
				</el-form-item>
				
				<el-form-item label="充值余额:" prop="amount">
					<el-input v-model="form.amount"></el-input>
				</el-form-item>
				
			  <el-form-item>
			    <el-button type="primary" @click="onSubmit">确定充值</el-button>
			  </el-form-item>
			</el-form>
			
			<div class="list clear" v-if="ViptimeConfig.vip_day_status == 1">
				<div class="title">
					<h2>套餐购买</h2>
				</div>
				<el-col :span="7" class="PackageList" v-for="(item,index) in PackageList" :key="index">
					<div class="name">{{item.name}}</div>
					<div class="price">{{item.price}}元</div>
					<el-button v-if="item.price > 5000" type="primary" @click="onPackage(item)">立即购买</el-button>
					<el-button v-else-if="item.price >= 4000" type="success" @click="onPackage(item)">立即购买</el-button>
					<el-button v-else-if="item.price >= 3000" type="warning" @click="onPackage(item)">立即购买</el-button>
					<el-button v-else-if="item.price >= 1000" type="infor" @click="onPackage(item)">立即购买</el-button>
				</el-col>
			</div>
			
			
			<div class="list">
				<div class="title">
					<h2>充值记录</h2>
				</div>
				<div class="list-con">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column
					        prop="money"
					        label="金额"
					        width="180">
						</el-table-column>
						<el-table-column label="充值时间" >
						  <template slot-scope="scope">
							  <div>{{ formatDate(scope.row.pay_time,true) }}</div>
						  </template>
						</el-table-column>
						<el-table-column label="充值状态" width="120" align="center" fixed="right">
						  <template slot-scope="scope" >
							  <el-tag type="success" v-if="scope.row.status == 1">充值成功</el-tag>
							  <el-tag type="warning" v-if="scope.row.status == 0">未 支 付</el-tag>
						  </template>
						</el-table-column>
					</el-table>
					<div class="pagination">
					    <div class="block">
					      <el-pagination
							background
							@size-change="handleSizeChange"
					        @current-change="handleCurrentChange"
					        :current-page.sync="list.page"
					        :page-sizes="[10,20,50,100, 200]"
					        :page-size="list.limit"
					        layout="total, sizes, prev, pager, next"
					        :total="total">
					      </el-pagination>
					    </div>
					</div>
				</div>
			</div>
			
			
			<el-dialog
			  title="微信扫码支付"
			  :visible.sync="dialogVisible"
			  width="20%"
			  :before-close="handleClose">
			  <div class="content">
				  <el-image :src="qrcode.qrcode" style="width: 200px; height: 200px;">
					  <div slot="error" class="image-slot">
					  	<i class="el-icon-picture-outline"></i>
					  </div>
				  </el-image>
				  <div class="money">充值余额：{{money}}元</div>
				  <div class="title" :class="{'zhifusc' : zhifu === true}">
					  {{ zhifu === true ?'提示:微信支付成功' : '' }}
				  </div>
			  </div>
			</el-dialog>
			
			<el-dialog
				title="余额支付"
				:visible.sync="yueVisible"
				width="20%"
				:before-close="handleClose">
				<div class="left-content">
					<div class="line">
						<label>用户余额：</label><span>{{user.now_money}}</span>
					</div>
					<div class="line">
						<label>购买套餐：</label><span>{{yueinfo.name}}</span>
					</div>
					<div class="line">
						<label>套餐金额：</label><span>{{yueinfo.price}}</span>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
				  <el-button type="primary" @click="yuefinish" :loading="loading">确认支付</el-button>
				</div>
			</el-dialog>
		</el-main>
	</el-container>
</template>

<script>
	import { isNumber } from '@/util/commons'
	import { setOfficereCharge,getRechargeList,setUserczAdd,getUserczView,getUserczIndex,getViptime,setViptimeKaiVip,getViptimeConfig } from '@/api/member.js';
	import { mapState, mapMutations, mapActions } from 'vuex'
	import { formatDate } from '@/util/commons'
    export default {
        name: "recharge",
		head: {
		    title: "充值中心",
		},
		computed: {
		  ...mapState({
		    user: state => state.member.storeInfo,
		  }),
		},
		data(){
			return {
				dialogVisible:false,
				yueVisible:false,
				yueinfo:{},
				yuezhifu:false,
				loading:false,
				qrcode:{},
				zhifu:false,
				money:0,
				form:{
					uid:'',
					amount:''
				},
				RechargeList:{},
				PackageList:[],
				rules:{
					amount:[
						{ required: true, message: '充值余额不能为空', trigger: 'blur'},
						{ validator: (rule, value, callback) => {
						  if (!value) {
							callback(new Error('金额不能为空'))
						  } else {
							if (!isNumber(value)) {
								callback(new Error('请输入正确金额'))
							} else {
								callback()
							}
						  }
						}, message: '请输入正确金额', trigger: 'blur'}
					]
				},
				jishi:null,
				tableData:[],
				total:0,
				list:{
					limit:10,
					page:1,
					status:1
				},
				ViptimeConfig:{}
			}
		},
		created() {
			this.getViptimeConfig()
		},
		mounted() {
			this.getRechargeList()
			this.getUserczIndex()
			this.getPackageList()
		},
		methods:{
			formatDate,
			...mapActions({
				setSaveStoreInfo:'SaveStoreInfo'
			}),
			getViptimeConfig(){
				getViptimeConfig().then(res=>{
					this.ViptimeConfig = res.data
				}).catch(err=>{
					console.log(err)
				})
			},
			handleSizeChange(val) {
				this.list.page = 1 
				this.list.limit = val
				this.getUserczIndex()
			},
			onPackage(data){
				this.yueVisible = true
				this.yueinfo = data
			},
			yuefinish(){
				this.loading = true
				setViptimeKaiVip({id:this.yueinfo.id}).then(res=>{
					this.$message.success(res.msg);
					let jishitime = setTimeout(()=>{
						this.loading = false
						this.yueVisible = false
						clearTimeout(jishitime)
					},3000);
				}).catch(err=>{
					this.loading = false
					console.log(err)
				})
			},
			getPackageList(){
				getViptime().then(res=>{
					console.log(res)
					this.PackageList = res.data.list
				}).catch(err=>{
					console.log(err)
				})
			},
			getUserczIndex(){
				getUserczIndex(this.list).then(res=>{
					console.log(res)
					this.tableData = res.data.list
					this.total = res.data.count
				})
			},
			handleCurrentChange(val){
				this.list.page = val
				this.getUserczIndex()
			},
			handleClose(done) {
				this.$confirm('确认放弃本次支付？')
				  .then(_ => {
						clearInterval(this.jishi)
						done();
				  })
				  .catch(_ => {});
			},
			finish(){
				this.zhifu = false
				this.jishi = setInterval(()=>{
					getUserczView({id:this.qrcode.id}).then(res=>{
						console.log(res)
						if(Number(res.data.status) === 1){
							clearInterval(this.jishi);
							this.zhifu = true;
							this.setSaveStoreInfo();
							let jishitime = setTimeout(()=>{
								this.dialogVisible = false
								clearTimeout(jishitime)
							},3000);
						}
					}).catch(err=>{
						this.dialogVisible = false
						clearInterval(this.jishi)
					})
				},2000)
			},
			getRechargeList(){
				getRechargeList().then(res=>{
					this.RechargeList = res.data.list
				}).catch(err=>{
					console.log(err.msg)
				})
			},
			onRecharge(data){
				if(data){
					console.log(data)
					  const loading = this.$loading({
					            lock: true,
					            text: '正在提交...',
					            spinner: 'el-icon-loading',
					            background: 'rgba(0, 0, 0, 0.7)'
					          });
					this.money = Number(data).toFixed(2)
					setUserczAdd({money:this.money}).then(res=>{
						loading.close();
						this.dialogVisible = true
						this.qrcode = res.data
						this.finish();
						// this.$message.success(res.msg);
					}).catch(err=>{
						console.log(err)
						loading.close();
						this.$message.error(err.msg);
					})
				}
			},
			onSubmit(){
					this.$refs.form.validate((valid) => {
						  if (valid) {
							  this.form.uid = this.user.uid
							  const loading = this.$loading({
							            lock: true,
							            text: '正在充值...',
							            spinner: 'el-icon-loading',
							            background: 'rgba(0, 0, 0, 0.7)'
							          });
							this.money = Number(this.form.amount).toFixed(2)
							setUserczAdd({money:this.form.amount}).then(res=>{
								loading.close();
								this.dialogVisible = true
								this.qrcode = res.data
								this.finish();
								// this.$message.success(res.msg);
							}).catch(err=>{
								console.log(err)
								loading.close();
								this.$message.error(err.msg);
							})
							// console.log('success submit!!');
						  } else {
							// console.log('error submit!!');
							return false;
						  }
					});
					
			},
		}
    }
</script>

<style scoped lang="scss">
.datainfo {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
}
.but {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}
.el-form {
    width: 700px;
    margin: 0 auto;
	.title {
	    border-bottom: 1px solid #ddd;
	    margin-bottom: 22px;
	}
}
.list {
    width: 700px;
    margin: 0 auto;
	margin-top: 80px;
	.title {
	    border-bottom: 1px solid #ddd;
	    margin-bottom: 22px;
	}
	.PackageList {
	    padding: 30px 4px;
	    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
	    margin: 14px;
	    text-align: center;
		.name {
			font-weight: bold;
		    font-size: 20px;
		    margin: 10px 0;
		}
		.price {
		    font-size: 28px;
		    color: #E85033;
		    font-weight: bold;
		    margin-bottom: 16px;
		}
	}
}
.RechargeList{
	margin-right: 4px;
	margin-bottom: 4px;
	display: inline-block;
}
.content {
    text-align: center;
	.title {
	    font-size: 16px;
	    font-weight: bold;
	    margin-top: 6px;
	}
	.money {
	    margin-top: 12px;
	    font-size: 15px;
	}
	.zhifusc{
		color: #409EFF;
	}
}

.pagination {
    text-align: center;
    margin-top: 20px;
    padding: 8px 0;
}
.left-content{
	.line {
	    margin: 16px 0;
		 label {
		    margin-right: 6px;
		}
	}
}

</style>
