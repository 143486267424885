<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #ddd;">
			<el-col :span="12">
				<div class="datainfo">
					个人资料
				</div>
			</el-col>
			<el-col :span="12">
				<!-- <div class="but">
					 <el-button type="primary">添加素材</el-button>
				</div> -->
			</el-col>
		</el-header>
		<el-main>
			<el-form ref="form" :model="form" label-width="80px">
				<div class="title">
					<h2>账号信息</h2>
				</div>
<!-- 				<el-row type="flex" class="row-bg" justify="center">
				  <el-col :span="12">
					  <el-form-item label="用户称呼">
					  	<el-input v-model="form.nickname"></el-input>
					  </el-form-item>
				  </el-col>
				  <el-col :span="12">
					  <el-form-item label="用户账号">
					  	<el-input v-model="form.username"></el-input>
					  </el-form-item>
				  </el-col>
				</el-row>
				<el-row type="flex" class="row-bg" justify="center">
				  <el-col :span="12">
					  <el-form-item label="用户密码">
					  	<el-input v-model="form.password"></el-input>
					  </el-form-item>
				  </el-col>
				  <el-col :span="12">
					  <el-form-item label="用户余额">
					  	<el-input v-model="form.username"></el-input>
					  </el-form-item>
				  </el-col>
				</el-row> -->
				
				<el-form-item label="用户头像">
					<div class=" word-flex word-flex-between">
						<el-upload
						  class="avatar-uploader"
						  action="https://app.facemake.cn/api/Base/upload"
						  :show-file-list="false"
						  :headers="importHeaders"
						  :auto-upload="false"
						  enctype="multipart/form-data"
						  :on-change="openFile"
						  :on-success="handleSuccess" >
						  <div style="border: 1px dashed #d9d9d9;border-radius: 50%;overflow: hidden;">
							  <img v-if="user.userpic" :src="user.userpic" class="avatar">
							  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
						  </div>
						</el-upload>
					</div>
			<!-- 		<div class="word-flex word-flex-start">
						<img :src="(user.userpic!==''?user.userpic:avatarimg)" style="display: block; width: 50px; height: 50px; border-radius: 50%;box-shadow: 0 0 2px 2px #ddd;" alt="">
						<span style="margin-left: 20px;">点击替换图片</span>
					</div> -->
				</el-form-item>
				
				<el-form-item label="用户余额">
					<span>{{user.now_money}}</span>
				</el-form-item>
				
				<el-form-item label="用户账号">
					<el-input v-model="user.username" :disabled="true"></el-input>
				</el-form-item>
				
				<el-form-item label="用户称呼" prop="nickname" :rules="[{ required: true, message: '用户称呼不能为空', trigger: 'blur'}]">
					<el-input v-model="form.nickname" @blur="nickname" placeholder="请输入用户称呼"></el-input>
				</el-form-item>
				
				<el-form-item label="用户密码" prop="password" :rules="[{ required: true, message: '用户密码不能为空', trigger: 'blur'}]">
					<el-input v-model="form.password"  type="password" show-password  placeholder="请输入密码"></el-input>
				</el-form-item>
				
				<el-form-item label="重复密码" prop="repassword" :rules="[{ required: true, message: '重复密码不能为空', trigger: 'blur'}]">
					<el-input v-model="form.repassword"  type="password" show-password  placeholder="请再次输入密码"></el-input>
				</el-form-item>

			  <el-form-item>
			    <el-button type="primary" @click="onSubmit">确定修改</el-button>
			  </el-form-item>
			</el-form>
			
			    <el-dialog title="头像剪裁" :width="dialogWidth" :visible.sync="dialogVisible" append-to-body>
			      <div class="cropper-content">
			        <div class="cropper" style="text-align:center">
			        <vueCropper
			            ref="cropper"
			            :img="option.img"
			            :outputSize="option.size"
			            :outputType="option.outputType"
			            :info="true"
			            :full="option.full"
			            :canMove="option.canMove"
			            :canMoveBox="option.canMoveBox"
			            :original="option.original"
			            :autoCrop="option.autoCrop"
			            :fixed="option.fixed"
			            :fixedNumber="option.fixedNumber"
			            :centerBox="option.centerBox"
			            :infoTrue="option.infoTrue"
			            :fixedBox="option.fixedBox"
			          ></vueCropper>
			        </div>
			      </div>
			      <div slot="footer" class="dialog-footer">
			        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
			        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
			      </div>
			    </el-dialog>
		</el-main>
	</el-container>
</template>

<script>
	import { resetPassword,toUpload,toUploadUserinfo } from '@/api/member.js';
	import { mapState, mapMutations, mapActions } from 'vuex'
    export default {
        name: "dataModification",
		head: {
		    title: "个人资料中心",
		},
		computed: {
		  ...mapState({
		    user: state => state.member.storeInfo,
		    token: state => state.member.token,
		  })
		},
		data(){
			return {
				dialogVisible:false,
				      option: {
				        img: '', // 裁剪图片的地址
				        info: true, // 裁剪框的大小信息
				        outputSize: 0.8, // 裁剪生成图片的质量
				        outputType: 'jpg', // 裁剪生成图片的格式
				        canScale: true, // 图片是否允许滚轮缩放
				        autoCrop: true, // 是否默认生成截图框
				        // autoCropWidth: 100, // 默认生成截图框宽度
				        // autoCropHeight: 100, // 默认生成截图框高度
				        fixedBox: false, // 固定截图框大小 不允许改变
				        fixed: true, // 是否开启截图框宽高固定比例
				        fixedNumber: [3, 3], // 截图框的宽高比例
				        full: true, // 是否输出原图比例的截图
				        canMoveBox: true, // 截图框能否拖动
				        original: true, // 上传图片按照原始比例渲染
				        centerBox: false, // 截图框是否被限制在图片里面
				        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				      },
				      picsList: [],  //页面显示的数组
				      // 防止重复提交
				      loading: false,
				form:{
					uid:'',
					nickname:'',
					password:'',
					repassword:''
				},
				dialogWidth: '30%',
				fileinfo: '',
				importHeaders:{
					Authorization:'',
				},
				avatarimg:require('@/assets/img/touxiang.jpeg'),
			}
		},
		created() {
			this.importHeaders.Authorization = this.token
			this.$nextTick(() => {
				this.form.nickname = this.user.nickname
			})
		},
		methods:{
			...mapActions({
				setSaveStoreInfo:'SaveStoreInfo'
			}),
			handleSuccess(res, file) {
				// console.log(res, file)
			        // this.imageUrl = URL.createObjectURL(file.raw);
			        this.option.img = res.data;
					this.dialogVisible = true
			      },
			openFile(file){
				const isLt5M = file.size / 1024 / 1024 < 5
				if (!isLt5M) {
				  this.$message.error('上传文件大小不能超过 5MB!')
				  return false
				}
				// console.log(file)
				this.fileinfo = file
				// 上传成功后将图片地址赋值给裁剪框显示图片
				this.$nextTick(() => {
				  this.option.img = URL.createObjectURL(file.raw)
				  this.dialogVisible = true
				})
				// console.log( file)
			},
			nickname(){
				if(!this.form.nickname || this.form.nickname == this.user.nickname){
					return false;
				}
				toUploadUserinfo({nickname:this.form.nickname}).then(resa=>{
					this.setSaveStoreInfo()
					this.$message.success(resa.msg);
				}).catch(err=>{
					this.$message.error(err.msg);
				})
			},
			onSubmit(){
					this.$refs.form.validate((valid) => {
						  if (valid) {
							  const loading = this.$loading({
							            lock: true,
							            text: '正在提交修改...',
							            spinner: 'el-icon-loading',
							            background: 'rgba(0, 0, 0, 0.7)'
							          });
							delete this.form.uid
							resetPassword(this.form).then(res=>{
								loading.close();
								this.$message.success(res.msg);
							}).catch(err=>{
								console.log(err)
								loading.close();
								this.$message.error(err.msg);
							})
							// console.log('success submit!!');
						  } else {
							// console.log('error submit!!');
							return false;
						  }
					});
					
			},
			    // 点击裁剪，这一步是可以拿到处理后的地址
			    finish() {
			      this.$refs.cropper.getCropBlob((data) => {
					   let formData = new FormData();
					   formData.append("file", data, 'touxiang.jpg');
						this.loading = true
						// 上传图片
						toUpload(formData).then(res=>{
							// 提交头像
							toUploadUserinfo({nickname:this.user.nickname,userpic:res.data}).then(resa=>{
								this.setSaveStoreInfo()
								this.$message.success(resa.msg);
								this.loading = false
								this.dialogVisible = false
							}).catch(err=>{
								this.loading = false
								this.dialogVisible = false
								this.$message.error(err.msg);
							})
						}).catch(err=>{
							this.loading = false
							this.dialogVisible = false
							console.log(err)
							this.$message.error(err.msg);
						})
						
			      })
			    }
		}
    }
</script>


<style lang="scss" scoped>
	.cropper-content {
	    .cropper {
	        width: auto;
	        height: 300px;
	    }
	}
.datainfo {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
}
.but {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}
.el-form {
    width: 600px;
    margin: 0 auto;
	.title {
	    border-bottom: 1px solid #ddd;
	    margin-bottom: 22px;
	}
}

.avatar-uploader{
	
	.el-upload {
	    border: 1px dashed #d9d9d9 !important;
	    border-radius: 6px;
	    cursor: pointer;
	    position: relative;
	    overflow: hidden;
		&:hover {
			border-color: #409EFF;
		}
		.avatar-uploader-icon {
		  font-size: 28px;
		  color: #8c939d;
		  width: 78px;
		  height: 78px;
		  line-height: 78px;
		  text-align: center;
		}
		.avatar {
		  width: 78px;
		  height: 78px;
		  display: block;
		}
	}
	
}
  
</style>

