<template style="height: 100%">
    <div id="app" style="height: 100%">
        <el-container v-loading="loading" style="height: 100% ;width:100%;">
            <el-header style="border-bottom: 2px solid rgb(33,43,54);background: rgb(39,51,65)">
                <el-button class="fa fa-mail-reply" style="background: rgb(39,51,65);border: black;float: left;margin-top: 10px;color: #fff;"
                           @click="back">&nbsp;
                </el-button>
                <!--<input type="text" v-model="post.textTitle" style="background: rgb(39,51,65); border:0;border-bottom: 1px solid black ;
                float: left;width: 300px;margin-top: 26px"
                       placeholder="在此处输入您的视频标题" >-->
                <el-input v-model="post.title" type="text" style="position: relative"  @focus="lol = 1"  suffix-icon="" placeholder="在此处输入您的视频标题" class="textTit"
                          @blur="lol = 0">
                    <i id="ddk" class="uilol el-icon-edit el-input__icon" slot="suffix" v-if="lol === 0"
                   style="margin-top: 10px;font-size: 21px;color:rgb(72,101,129);position: absolute;right: -40px ">
                </i>
                </el-input>

                <el-button
                        style="float: right;background: rgb(0,90,235);margin-top: 10px;margin-right: 80px;color: #fff;border: 0;border-radius: 10px"
                        @click="butTc2">生&nbsp;成&nbsp;视&nbsp;频
                </el-button>
                <el-button type="info"
                           style="float: right;margin-top: 10px;margin-right: 10px;color: #fff;;border-radius: 10px"
                           @click="butTc">保&nbsp;存
                </el-button>

            </el-header>
            <!--<el-dialog
                    title="保存"
                    :visible.sync="postZH"
                    width="400px"
                    >

              <img width="80px" style="border-radius: 50%;background: #d9d9d9;margin-bottom: 20px" :src="listCC[0].s_pic" alt="">
              <br>
              <el-form style="margin-right: 30px" ref="form" :model="post" label-width="80px">
                <el-form-item label="标题">
                  <el-input type="text" v-model="post.title" placeholder="在此处输入您的视频标题"></el-input>
                </el-form-item>
                <el-form-item label="配乐选项">

              <el-select style="float: left" v-model="post.soundtrack" placeholder="请选择配乐">
                <el-option label="公司" value="corporate"></el-option>
                <el-option label="鼓舞人心" value="inspirational"></el-option>
                <el-option label="现代" value="modern"></el-option>
                <el-option label="城市" value="urban"></el-option>
              </el-select>
                </el-form-item>
                <el-form-item label="描述">
              <el-input type="textarea" v-model="post.description" :rows="8" placeholder="在此处输入您的视频描述"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
          <el-button @click="postZH = false">取 消</el-button>
          <el-button type="primary" @click="postQBD">保存草稿</el-button>
      &lt;!&ndash;    <el-button type="success" @click="uplode">上传</el-button>&ndash;&gt;
        </span>
            </el-dialog>-->
            <el-container class="Box-container" style="height: 100%">
                <el-aside class="menu-list" width="300px" style="height: 100%;background: rgb(39,51,65);" @mouseover="vcl">
                    <div style="margin-top: 50px;margin-left: 50px">

                            <aadraggable @update="down(ssr,Bood)" v-model="listCC" style="position: relative">
                            <transition-group>
                            <div class="img-box" style="margin-bottom:20px;" v-for="(iten,indexs) in listCC"
                                 @mouseover="HJdel(indexs)" @mouseleave="FHJdel" :key="indexs"
                                 @click="single(indexs,iten)">
                                <span style="position: absolute;left: -20px;color: white">{{indexs+1}}.</span>

                                <!--              <div class="gtgtG"></div>-->


                                <i @click="deleteItem(indexs)" class="koll"></i>
                                <div class="img-box" v-if="iten.videoDEX === 0">

                                    <img class="imgs-1" :class="ssr === indexs?'kl':'lk'" :src="iten.data_url">
                                </div>
                                <div class="img-box" v-else-if="iten.videoDEX === 1">
                                    <img class="imgs-1" :class="ssr === indexs?'kl':'lk'"
                                         v-if="iten.filetype == 'image/png'||iten.filetype=='image/jpeg'||iten.filetype=='image/svg+xml'"
                                         style="" :src="iten.data_url">
                                    <video class="imgs-1" :class="ssr === indexs?'kl':'lk'" style=""
                                           v-if="iten.filetype == 'video/webm'||iten.filetype=='video/mp4'"
                                           :src="iten.data_url">
                                        您的浏览器不支持视频播放
                                    </video>
                                </div>
                                <img :class="'img-'+indexs" style="position:absolute;"
                                     :style="{height:iten.value+'%',margin:iten.margin,left:iten.left,right:iten.right,background:iten.backgrounds,borderRadius:iten.borderRadius,bottom:-2+'px'}"
                                     :src="iten.s_pic">
                                <div v-if="hjk === indexs">
                                    <div @mouseleave="XHJdel">
                                        <div style="background: white;width: 87px;height: 50px;padding: 5px;border-radius: 5px;position: absolute;right: -94px;bottom: 70px;opacity: 0"></div>
                                        <div style="background: white;width: 87px;height: 50px;padding: 5px;border-radius: 5px;position: absolute;right: -101px;bottom: 70px">
                                            <div style="margin-top: 2px" class="Del2" @click="copy1">
                                                <div style="width: 84px;margin-left: 4px;border-radius: 5px">
                                                    <span style="color: rgb(66,91,115)">复制&nbsp;<i class="el-icon-document-copy"></i></span>
                                                </div>
                                            </div>
                                            <div style="margin-top: 5px;" class="Del1"  @click="ycDel(indexs)">
                                                <div style="width: 84px;margin-left: 4px;border-radius: 5px;">
                                                    <span style="color: rgb(66,91,115)">删除&nbsp;<i class="el-icon-delete"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </transition-group>
                        </aadraggable>
                        <div v-if="listCC.length<6" style="margin-top: 20px;color: rgb(71,100,129);">
                            <div style="border: 2px solid rgb(71,100,129);border-radius: 8px;width: 150px;height: 100px;">
                                <div class="addMB" style="" @click="addMaterial">
                                    <div class="el-icon-circle-plus" style="">&nbsp;</div>
                                    <span style="display: inline-block">新增素材</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-aside>
                <el-main style="background: rgb(33,43,54)">
                    <div class="imgbox" v-model="listCC">
                        <div class="imgbox" v-if="listCC[ssr].videoDEX == 0">
                            <div style="position: absolute;top: -397px;left: -692px;">
                                <img class="img1" style="border: 1px solid white;border-radius: 40px;"
                                     v-if="listCC.length > 0 " :src="listCC[ssr].data_url">
                            </div>
                            <img class="img2" v-if="listCC.length > 0 && listCC[ssr].activeIndex == '1'"
                                 :style="{height:listCC[ssr].value+'%',margin:listCC[ssr].margin,left:listCC[ssr].left,right:listCC[ssr].right}"
                                 :src="dis === true?listCC[ssr].s_pic:listCC[ssr].pic">
                            <img class="img2" v-if="listCC.length > 0 && listCC[ssr].activeIndex == '2'"
                                 :style="{height:listCC[ssr].value+'%',margin:listCC[ssr].margin,left:listCC[ssr].left,right:listCC[ssr].right,top:listCC[ssr].top,transform:listCC[ssr].transform,border:listCC[ssr].border,borderRadius:listCC[ssr].borderRadius,background:listCC[ssr].backgrounds}"
                                 :src="dis === true?listCC[ssr].s_pic:listCC[ssr].pic">
                        </div>
                        <div class="imgbox" v-if="listCC[ssr].videoDEX == 1"  v-loading="loadings" >
                            <div class="videoXP" style="">
                                <div >

                                <div style="position: absolute;top: -397px;left: -692px;">
                                    <div>
                                        <img class="img1" style="border: 1px solid white;border-radius: 40px;"
                                             v-if="listCC[ssr].filetype == 'image/png'||listCC[ssr].filetype=='image/jpeg'||listCC[ssr].filetype=='image/svg+xml'"
                                             :src="listCC[ssr].data_url">
                                    </div>
                                    <div @mouseover="YRvideo"  v-if="listCC[ssr].filetype == 'video/webm'||listCC[ssr].filetype=='video/mp4'" style="">
                                        <video @canplay="BFtime" @timeupdate="BFSStime" class="img1" id="BFvideo" style="border: 1px solid white;border-radius: 40px;float: left"
                                               :src="listCC[ssr].data_url">
                                            您的浏览器不支持视频播放
                                        </video>
                                    </div>
                                </div>
                                <img class="img2" @mouseover="YRvideoFo = 1" v-if="listCC.length > 0 && listCC[ssr].activeIndex == '1'"
                                     :style="{height:listCC[ssr].value+'%',margin:listCC[ssr].margin,left:listCC[ssr].left,right:listCC[ssr].right}"
                                     :src="dis === true?listCC[ssr].s_pic:listCC[ssr].pic">
                                <img class="img2" @mouseover="YRvideoFo = 1" v-if="listCC.length > 0 && listCC[ssr].activeIndex == '2'"
                                     :style="{height:listCC[ssr].value+'%',margin:listCC[ssr].margin,left:listCC[ssr].left,right:listCC[ssr].right,top:listCC[ssr].top,transform:listCC[ssr].transform,border:listCC[ssr].border,borderRadius:listCC[ssr].borderRadius,background:listCC[ssr].backgrounds}"
                                     :src="dis === true?listCC[ssr].s_pic:listCC[ssr].pic">
                            </div>

                            </div>
                            <div class="img2" v-if="listCC[ssr].filetype == 'video/webm'||listCC[ssr].filetype=='video/mp4'"
                                 style="border: 1px solid black;border-radius: 11px;width: 561.3px;height: 27px;
                                 background: black;;left: -12.5px;bottom: -31px">
                                <div v-if="">
                                    <div class="fa fa-play play_pause" style="color: white;position: absolute;left: 17px;top: 3px;
                                font-size: 22px;cursor: pointer" @click="KSvideo"></div>
                                    <div class="djggg" @click="djggg" style="background: blue;width: 276px;position: absolute;left: 60px;top: 14px;border-radius: 5px;cursor: pointer">
                                        <!--<span class="progress" style="border-radius: 3px">
                                            <div style="width: 10px;height: 10px;border-radius: 50%;background: white;position: absolute;right: 0;top: -5px"></div>
                                        </span>-->
                                        <el-slider style="background: black;margin-top: -5px;height: 5px;width: 350px" @change="SHEvideo" v-model="listCC[ssr].KUAN"></el-slider>
                                    </div>
                                    <div class="timer">
                                        <span class="progress_timer">00:00:00</span>/
                                        <span class="duration_timer">{{innerTextWS}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style="margin-top: 20px">
                        <div style="border-bottom: 2px solid rgb(59,159,248);width: 563px;color: #fff;margin: 16px auto;margin-top: 60px;padding-bottom: 10px">
                            请设置您需要朗读的语言文字
                        </div>
                        <div style="width: 537px;margin: auto; height: 340px;  background: white none repeat scroll 0% 0%; border: 1px solid black; border-radius: 10px; margin-top: 20px;padding: 13px 13px;">
                            <!--              <textarea style="width: 100%; height: 270px; background: white none repeat scroll 0% 0%; border: 1px solid rgb(217, 226, 236); border-radius: 10px;  ">-->

                            <!--              </textarea>-->
                            <el-input
                                    class="gtr"
                                    type="textarea"
                                    :rows="9"
                                    placeholder="请在此处输入或粘贴，我们支持40+种语言的视频脚本."
                                    style="font-size: 20px;position: relative"
                                    v-model="scriptText"
                                    :maxlength="maxlength"
                                    show-word-limit
                                    resize="none"
                                    @input="bctext">
                            </el-input>
                            <!--                            <div style="position: absolute;bottom: 160px;right: 680px"><span>{{maxlength}}</span>11111</div>-->
                            <div>
                                <el-button type="success" style="height:48px;width: 110px " icon="el-icon-search"
                                           @click="squery">自动识别
                                </el-button>
                                <el-button v-loading="loadang"
                                           style="width: 77.5%;margin-top: 10px;text-align: left;position: relative"
                                           @click="acbk" v-if="this.listCC[ssr].language_cn != ''">
                                    <img :src="this.listCC[ssr].icon" style="vertical-align: middle" width="30px"
                                         alt="">&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style="font-size: 20px;vertical-align: middle">{{this.listCC[ssr].language_cn}}-{{this.listCC[ssr].name}}</span>
                                    <i style="font-size: 20px;vertical-align: middle;position: absolute;right: 10px"
                                       class="el-icon-d-caret el-icon--right"></i>
                                </el-button>
                                <el-button v-loading="loadang"
                                           style="width: 77.5%;margin-top: 10px;color: rgb(130,154,181);text-align: left;position: relative"
                                           v-else>
                                    <span style="vertical-align: middle;font-size: 20px;">请输入上面输入框，选择人像</span>
                                    <i style="margin-left: 110px;font-size: 20px;vertical-align: middle;position: absolute;right: 10px"
                                       class="el-icon-d-caret el-icon--right"></i>
                                </el-button>
                            </div>
                        </div>
                    </div>
                </el-main>
                <el-dialog
                        top="230px"
                        style="text-align: left;;border-radius: 10px !important;"
                        :visible.sync="dialogVisible"
                        width=" 618px"
                        :before-close="handleClose">
                    <div style="margin-left: 9px;font-size: 28px">选择语言</div>
                    <div style="margin-left: 9px;margin-top: 20px;margin-bottom: 5px">索引到的语音</div>
                    <div style="height: 350px;width: 100%;border-radius: 10px;overflow: hidden;overflow-y: auto;">
                    <div v-for="(iten,index) in QBvoice"
                         style=""
                         class="jshowsss"
                        >
                        <div @click="languageSelect(index)">
                            <span style="vertical-align: middle">
                          <img :src="iten.icon" width="40px" alt="">
                        </span>
                            <span style="font-size: 17px;color: rgb(130,154,177);vertical-align: middle">
                          {{iten.language_cn}}-{{iten.name}}
                        </span>
                        </div>
                        <div class="Mider" style="">
                            <div class="jshow"  style="margin: auto;border: 1px solid rgb(188,204,220);margin-top: 4px;
                            border-radius: 50%;width: 20px;height: 20px;line-height: 18px;background: rgb(188,204,220);color: rgb(36,59,83)">
                                <i  @click="boFmiusic(index,iten,2)" v-if="!play && index == ace" class="fa fa-pause"></i>
                                <i  @click="boFmiusic(index,iten,1)" v-else-if="play && index == ace" style="margin-right: 10px !important;" class="fa fa-play"></i>
                                <i  @click="boFmiusic(index,iten,3)" v-else class="fa fa-play" style=""></i>
                            </div>
                        </div>
                    </div>
                    </div>
					<audio :src="fileMP3" class="fa fa-camera-retro" id="opop">
					    您的浏览器不支持 audio 标签。
					</audio>
                </el-dialog>
                <el-aside width="500px" style="">
                    <el-container style="width: 100%;height: 100%;">
                        <el-aside width="0"></el-aside>
                        <el-main class="menu-list" style="width: 600px;height: 100%;background: rgb(39,51,65)">
                            <div v-if="indexG===1" class="list_box">
                                <el-main class="menu-list" style="width: 400px;height: 600px">
                                    <div style="color: white;margin-top: -20px;margin-bottom:20px; text-align: left;">
                                        选择演员、大小和对齐方式
                                    </div>
                                    <hr>
                                    <div style="display: flex">
                                        <div>

                                            <br>
                                            <div class="list" style="float: left;margin-top: 10px"
                                                 v-for="(item, index) in anmList" :key="index" @click="Onclick(index)">
                                                <div>
                                                    <img :class="active==index?'active ':'list img'"
                                                         style="background: white;border-radius: 8px ;"
                                                         :src="item.pic"/>
                                                </div>
                                                <span style="color: white;float: left">{{item.name}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </el-main>
                            </div>
                            <div v-if="indexG===2" style="color: white;width: 530px;margin-left: 20px">
                                <div style="margin-top: 0">
                                    <div style="color: white;width: 360px">背景素材</div>
                                    <div>
                                        <el-tabs v-model="actveE" @tab-click="" style="width: 360px">
                                            <el-tab-pane label="颜色" name="first">
                                                <el-main style="width: 380px;height: 500px;margin-top: 20px">
                                                    <div style="display: flex;margin-left: -20px">
                                                        <div>
                                                            <div v-for="(item, index) in this.backgroundGET"
                                                                 :key="index" @click="dClick(index)"
                                                                 class="backgroundGETLIST" style=""
                                                                 v-if="item.type == 1">
                                                                <div>
                                                                    <img :class="active2 == index?'kk':'ll'"
                                                                         width="90px" :src="item.data_url"/>
                                                                    <span>{{item.name}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-main>
                                            </el-tab-pane>
                                            <el-tab-pane label="图片" name="second">
                                                <el-main style="width: 380px;height: 500px;margin-top: 20px">
                                                    <div style="display: flex">
                                                        <div style="margin-left: -20px">
                                                            <div v-for="(item, index) in this.backgroundGET"
                                                                 style="width: 100px;display: flex;float: left;margin: 0  5px 0 5px"
                                                                 @click="dClick(index)" :key="index"
                                                                 v-if="item.type == 2">
                                                                <div>
                                                                    <img :class="active2 == index?'kk':'ll'"
                                                                         width="90px" :src="item.data_url"/>
                                                                    <span>{{item.name}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-main>
                                            </el-tab-pane>
                                            <el-tab-pane label="上传" name="KILL">
                                                <!--                        <i slot="suffix" class="el-input__icon el-icon-date"></i>-->

                                                <el-main style="width: 380px;height: 730px;margin-top: 20px">
                                                    <div style="display: flex">

                                                        <div style="margin-left: -20px">

                                                            <div class=""
                                                                 style="width: 100px;display: flex;float: left;margin:10px 0 10px 10px;height: 60px;border: 1px solid white;border-radius: 8px;text-align: center;cursor: pointer">

                                                                <div style="">

                                                                    <el-upload class="avatar-uploader"
                                                                               action="https://app.facemake.cn/api/Base/assets"
                                                                               v-bind:data="{FoldPath:'/'}"
                                                                               v-bind:headers="importHeaders"
                                                                               v-bind:on-progress="uploadVideoProcess"
                                                                               v-bind:on-success="handleVideoSuccess"
                                                                               v-bind:before-upload="beforeUploadVideo"
                                                                               v-bind:show-file-list="false">
                                                                        <div class="avatarUpload">
                                                                            <div>
                                                                                <div v-if="videoForm.showVideoPath =='' && !videoFlag"
                                                                                     class="el-icon-plus avatar-uploader-icon"
                                                                                     style=""></div>
                                                                                <div v-if="videoForm.showVideoPath =='' && !videoFlag"
                                                                                     style="color: darkgrey;font-size: 10px;">
                                                                                    素材上传
                                                                                    <br>1920*1080
                                                                                </div>
                                                                                <el-progress v-if="videoFlag == true"
                                                                                             type="circle"
                                                                                             :width="40"
                                                                                             v-bind:percentage="videoUploadPercent"
                                                                                             style=""></el-progress>
                                                                            </div>
                                                                        </div>
                                                                    </el-upload>
                                                                </div>
                                                            </div>

                                                            <div v-for="(item, index) in this.tableData"
                                                                 style="width: 90px;display: flex;float: left;margin: 10px;cursor: pointer"
                                                                 :key="index">
                                                                <div style="display: flex;position:relative;" >

                                                                    <el-image  @click="rtClick(index)"
                                                                         style="height: 60px;width: 100px"
                                                                         v-if="item.filetype == 'image/png'||item.filetype=='image/jpeg'||item.filetype=='image/svg+xml'"
                                                                         :class="videoMax == index?'kk':'ll'"
                                                                         :src="item.filepath"/>
                                                                    <video @click="rtClick(index)"
                                                                           style="height: 60px;width: 100px"
                                                                           :class="videoMax == index?'kk':'ll'"
                                                                           v-if="item.filetype == 'video/webm'||item.filetype=='video/mp4'"
                                                                           :src="item.filepath">
                                                                        您的浏览器不支持视频播放
                                                                    </video>
                                                                    <div style="position: absolute;height: 60px;width: 100px;background: black;left: 2px;top: 2px;line-height: 60px;opacity: 0.5;font-size: 12px" v-if="item.status == 0">转码中...</div>
                                                                </div>
                                                                <!--<div style="display: flex" v-if="item.status == 0">
                                                                    <el-image style="height: 60px;width: 100px"
                                                                              v-if="item.filetype == 'image/png'||item.filetype=='image/jpeg'||item.filetype=='image/svg+xml'"
                                                                              :class="videoMax == index?'kk':'ll'"
                                                                              :src="item.filepath"></el-image>
                                                                    <video style="height: 60px;width: 100px"
                                                                           :class="videoMax == index?'kk':'ll'"
                                                                           v-if="item.filetype == 'video/webm'||item.filetype=='video/mp4'"
                                                                           :src="item.filepath">
                                                                        您的浏览器不支持视频播放
                                                                    </video>
                                                                </div>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-main>
                                            </el-tab-pane>
                                        </el-tabs>
                                    </div>
                                </div>
                            </div>
                            <div v-if="indexG===3" style="padding-left: 20px;height: 500px">

                                <div style="text-align: left;;color:  white;margin-bottom: 20px">请选择您的背景音乐</div>
                                <hr style="border: 1px solid white;margin-bottom: 20px">
                                <div v-for="(values,keys) in musicList" style="" class="bjMusic">
                                    <div class="PlayVB" style="float: left;line-height: 18px;border: 1px solid rgb(188,204,220);
                            border-radius: 50%;width: 20px;height: 20px;background: rgb(188,204,220);color: rgb(36,59,83);margin-top: 10px;cursor: pointer" >
                                        <i @click="playSwitch(values,keys,2)" v-if="!playMusic && keys == muPl"  class="fa fa-pause Playi1" style=""></i>
                                        <i @click="playSwitch(values,keys,1)" v-else-if="playMusic && keys == muPl"  class="fa fa-play Playi2" style=""></i>
                                        <i @click="playSwitch(values,keys,3)" v-else class="fa fa-play Playi3"></i>
                                    </div>
                                    <div style="margin-left: 20px">
                                        <div style="color: white;">{{values.name}}</div>
                                        <div style="color: rgb(127,150,173);font-size: 12px">{{values.time}}</div>
                                    </div>
                                    <div class="muse" style="cursor: pointer">
                                        <button class="button1" @click="choice(values,keys,2)" v-if="musicUse == false && playChoice == keys">取消</button>
                                        <button class="button2" @click="choice(values,keys,1)" v-else-if="musicUse == true && playChoice == keys">选择</button>
                                        <button class="button3" @click="choice(values,keys,3)" v-else>选择</button>
                                    </div>
                                    <audio ref="audioUrlRefText" :src="musicPlay" style="" id="DFU">
                                        您的浏览器不支持 audio 标签。
                                    </audio>
                                </div>
                            </div>
                            <div v-if="indexG===4">44444</div>
                            <div v-if="indexG===5">55555</div>
                            <el-aside width="330" style="overflow: hidden">
                                <div v-if="indexG===1" style="color: white;width: 380px;margin-left: 10px">
                                    <hr style="border: 1px solid rgb(33,43,54);width: 848px;margin-left: -80px">
                                    <el-menu :default-active="activeIndex"
                                             style="display: flex;background: rgb(39,51,65);color: rgb(189,193,197);border: 0;width: 330px"
                                             class="el-menu-demo" mode="horizontal" @select="selectAll">
                                        <el-menu-item index="1" class="el-icon-user-solid"
                                                      style="background: rgb(39,51,65);color: white;width: 50%">&nbsp;&nbsp;&nbsp;头像位置
                                        </el-menu-item>
                                        <el-menu-item index="2" class="el-icon-picture-outline-round"
                                                      style="background: rgb(39,51,65);color: white;width: 50%">&nbsp;&nbsp;&nbsp;头像样式
                                        </el-menu-item>
                                    </el-menu>
                                    <el-card v-if="activeIndex === '1'" class="box-card"
                                             style="">
                                        <div class="text item" style="margin-top: -10px">
                                            <el-button v-if="listCC[ssr].post.avatarSettings.horizontalAlign != 'left'||Dleft != 1" style="width: 80px" @click="leftBtn">左</el-button>
                                            <el-button v-if="listCC[ssr].post.avatarSettings.horizontalAlign == 'left'||Dleft == 1" style="width: 80px" @click="leftBtn" type="primary">左</el-button>
                                            <el-button v-if="listCC[ssr].post.avatarSettings.horizontalAlign != 'center'||Dcenter != 1" style="width: 80px" @click="butBtn">中</el-button>
                                            <el-button v-if="listCC[ssr].post.avatarSettings.horizontalAlign == 'center'||Dcenter == 1" style="width: 80px" @click="butBtn" autofocus type="primary">中</el-button>
                                            <el-button v-if="listCC[ssr].post.avatarSettings.horizontalAlign != 'right'||Dright != 1" style="width: 80px" @click="rigBtn">右</el-button>
                                            <el-button v-if="listCC[ssr].post.avatarSettings.horizontalAlign == 'right'||Dright == 1" style="width: 80px" @click="rigBtn" type="primary">右</el-button>
                                            <!--<el-button style="width: 80px" class="gtyR1" @click="leftBtn" >左</el-button>
                                            <el-button style="width: 80px" class="gtyR2" @click="butBtn" autofocus >中</el-button>
                                            <el-button style="width: 80px" class="gtyR3" @click="rigBtn"  >右</el-button>-->
                                            <hr>
                                            <div class="block" style="margin-top: -10px">
                                                <el-slider :min="5" style="width: 200px;margin: 0 auto"
                                                           v-if="listCC.length > 0" :step="5" @input="changeTabs"
                                                           v-model="htoh"></el-slider>
                                            </div>
                                        </div>
                                    </el-card>
                                    <div v-if="activeIndex==='2'" style="margin-top: 20px">
                                        <el-card
                                                style="width: 226px;;display: inline-block;height: 90px;margin-right: 20px;float: left">
                                            <el-slider :min="5" @input="changeTabs" v-if="listCC.length > 0" :step="5"
                                                       v-model="htoh"></el-slider>
                                        </el-card>
                                        <el-card style="width: 80px;display: inline-block;height: 90px;float: left">
                                            <el-color-picker v-model="color1" @active-change='colorTo'>1</el-color-picker>
                                            <div>颜色</div>
                                        </el-card>
                                    </div>
                                </div>
                                <div v-if="indexG===4">44444</div>
                                <div v-if="indexG===5">55555</div>
                            </el-aside>
                        </el-main>
                        <el-aside width="100px" style="background: rgb(33,43,54);">

                            <div @click="indexG=1" :class="indexG===1?'elcc dic':'dic'"
                                 style="font-size: 20px;position: relative;cursor: pointer">
                                <div class="el-icon-user-solid" style="" :class="indexG===1?'jkw':'jwk'"></div>
                                <div style="font-size: 12px;position: absolute;top: 17px;left: 38px;"
                                     :class="indexG===1?'jkw':'jwk'">人物
                                </div>
                            </div>
                            <div @click="indexG=2" :class="indexG===2?'elcc dic':'dic'"
                                 style="font-size: 20px;position: relative;cursor: pointer">
                                <div style="font-size: 20px;" :class="indexG===2?'jkw':'jwk'"
                                     class="el-icon-picture"></div>
                                <div style="font-size: 12px;position: absolute;top: 17px;left: 38px;"
                                     :class="indexG===2?'jkw':'jwk'">背景
                                </div>
                            </div>
                            <div @click="indexG=3" :class="indexG===3?'elcc dic':'dic'"
                                 style="font-size: 20px;position: relative;cursor: pointer">
                                <div style="font-size: 20px;" :class="indexG===3?'jkw':'jwk'"
                                     class="fa fa-music"></div>
                                <div style="font-size: 12px;position: absolute;top: 19px;left: 38px;"
                                     :class="indexG===3?'jkw':'jwk'">配乐
                                </div>
                            </div>
                        </el-aside>
                    </el-container>
                </el-aside>
            </el-container>
        </el-container>

    </div>
</template>

<script>
    import niu from '@/util/uitotree.js'
    import draggable from 'vuedraggable'
    import VueCropper from 'vue-cropper'
    import {mapState} from "vuex";
    import {
        getXxxfile,
        getmiuseConfig,
        getvoiceConfig,
        getvoiceadd,
        getvoicebj,
        getAvatars,
        getuplodebj,
        getUservideoInfo,
        getUservideoUpdate,
        getbjMiuse
    } from '@/api/member.js';
    import {formatDate,formatTime} from '@/util/commons'

    export default {
        computed: {
            ...mapState({
                user: state => state.member.storeInfo,
                token: state => state.member.token,
            })
        },
        components: {'aadraggable': draggable},
        data() {
            return {
                playRadioTime:[],
                Bood:{},
				fileMP3:'',
                maxlength: 3500,
                countMax: 0,
				play:true,
                videoMax: -1,
                videoDEX: 0,
                ggty: '',
                active: 0,
                indexG: 1,
                anmList: [],
                value2: 0,
                Gps: 0,
                cdn: 1,
                activeIndex: '1',
                color1: '#409EFF',
                fileImgList: [],
                isCropper: false,
                previews: {},
                actveE: 'first',
                listST: {
                    text: ''
                },
                actve: '1',
                active2: 0,
                backgroundGET: [],
                listCC: [
                    {
                        pic: "https://static-yjzw-net.yjzw.net/admin/202109/202109091141050138511.png",
                        s_pic: "https://static-yjzw-net.yjzw.net/admin/202109/202109171022010168143.png",
                        left: '0px',
                        right: '0px',
                        videoDEX: 0,
                        margin: '0 auto',
                        activeIndex: '1',
                        data_url: '',
                        value: 100,
                        gender: '2',
                        scriptText: '', //输入框内文字
                        language_cn: '',
                        name: '',
                        icon: '',
                        data_value: '',
                        active2: 0,
                        videoMax: 0,
                        active: 0,
                        KUAN:0,
                        videoCurrent:0,
                        totalT:0,
                        presentT:0,
                        BFvideo:null,
                        //--------------
                        post: {
                            scriptText: '',
                            avatarSettings: {
                                backgroundColor: '',
                                voice: '',
                                horizontalAlign: '',
                                scale: 100,
                                style: 'rectangular',
                                seamless: false,
                            },
                            background: '',
                            avatar: "anna_costume1_cameraA",
                            backgroundSettings: {
                                videoSettings: {
                                    shortBackgroundContentMatchMode: "freeze",
                                    longBackgroundContentMatchMode: "trim",
                                }
                            }
                        }
                    }
                ],
                listQB: [
                    {
                        pic: '',
                        s_pic: '',
                        data_url: ''
                    }
                ],
                listCopy: [],
                importHeaders: {
                    Authorization: '',
                },
                ssr: 0,

                //上传视频
                tableData: [],

                videoFlag: false,
                //是否显示进度条
                videoUploadPercent: "",
                //进度条的进度，
                isShowUploadVideo: false,
                //显示上传按钮
                videoForm: {
                    showVideoPath: ''
                },
                dis: true,
                htoh: 100,
                PDvoice: "",
                voice: [],
                QBvoice: [],
                ddckes:false,
                dialogVisible: false,
                prng: 0,
                genderD: 0,
                GMO: 0,
                OMG: 100,
                rss: -1,

                horizontalAlign: '',

                post: {
                    title: '新视频',//标题
                    description: 'yjzw',//描述
                    soundtrack: '',//配乐选项
                    input: [],//视频数据【多个片段】
                },
                lol: 0,
                postZH: false,
                scriptText: '',
                vdeiu: '',
                loading: false,
                loadang: false,
                loadings:false,
                postQBRT: [],
                TYU: 0,
                resId: '',
                del: -1,
                hjk: -1,
                countcc: -1,
                dataId: 0,
                gkh: -1,
                backd: 0,
                ace:-1,
                musicList:[],
                musicUse:false,
                playChoice:-1,
                playMusic:true,
                musicPlay:'',
                muPl:-1,
                bhu:'',
                Dleft:0,
                Dcenter:1,
                Dright:0,
                totalT:0,
                presentT:0,
                YRvideoFo:0,
                KUAN:0,
                innerTextWS:'00:00:00'
            }
        },
        created() {
            this.get()
            this.getMiuse()
            this.importHeaders.Authorization = this.token
            this.$nextTick(() => {
                niu.init(document.querySelector(".imgbox"), document.querySelector(".img-box"));
            })
        },
        watch: {
            ssr: function (val, oval) {
                this.ssr = val
            },
            totalT: function(){
                var progress = document.querySelector('.progress')
                //0.实现进度条
                //1.获取所需元素
                //2.根据时间来实现百分比
                /*var percent = this.presentT/this.totalT*100
                progress.style.width = percent+'%'*/
            },
            fileMP3: function (val, oval) {
                this.fileMP3 = val
            },
            color1: function () {
                this.colorTo()
            },
            value: function () {
                this.changeTabs()
            }
        },
        // totalT presentT
        methods: {
            vcl(){


            },
            rtClick(index) {
                this.listCC[this.ssr].videoDEX = 1
                console.log('grtrgrt', index)
                this.videoMax = index
                this.listCC[this.ssr].videoMax = index
                this.rss = index
                let ssr = this.ssr
                this.listCC[ssr].data_url = this.tableData[index].filepath
                console.log('this.listCC[ssr]', this.listCC[ssr])
                this.listCC[ssr].filetype = this.tableData[index].filetype
                console.log('this.listCC[this.ssr].filepath', this.listCC[ssr].filetype);
                if (this.listCC[this.ssr].filetype == 'video/webm'||this.listCC[this.ssr].filetype =='video/mp4'){
                    let BFvideo = document.getElementById('BFvideo')
                    let play_pause = document.querySelector('.play_pause')
                    if (BFvideo.paused){

                    }else {
                        play_pause.classList.add('fa-play')
                        play_pause.classList.remove('fa-pause')
                    }

                }
            },
            single(indexs, iten) {

                console.log('ptptptptp', indexs, iten)
                console.log('sdfsdfsdfs', this.listCC[this.ssr])

                let play_pause = document.querySelector('.play_pause')
                if (this.listCC[this.ssr].filetype != 'video/webm'||this.listCC[this.ssr].filetype !='video/mp4'){
                    console.log(play_pause)
                }else {
                    this.listCC[this.ssr].BFvideo.pause()
                    play_pause.classList.add('fa-play')
                    play_pause.classList.remove('fa-pause')
                }
                // console.log('xuddsa',videoObj)
                // this.listCC[indexs] = iten
                this.Bood = iten
                this.ssr = indexs
                this.OMG = iten.value

                this.color1 = this.listCC[this.ssr].backgrounds

                this.htoh = this.listCC[this.ssr].post.avatarSettings.scale
                console.log('this.listCC[this.ssr].post.avatarSettings.scale',this.listCC)
                this.activeIndex = this.listCC[this.ssr].activeIndex
                this.scriptText = this.listCC[this.ssr].post.scriptText
                this.$nextTick(()=> {
                    let videoObj = document.getElementById('BFvideo')
                    if (this.listCC[this.ssr].filetype == 'video/webm'||this.listCC[this.ssr].filetype =='video/mp4') {
                        // videoObj.duration = this.listCC[this.ssr].totalT
                        videoObj.currentTime = this.listCC[this.ssr].presentT
                        this.loadings = true
                    }
                })
                // this.hjk = -1
                if (this.listCC[this.ssr].activeIndex == '2'){
                    this.selectAll('2')
                }

            },
            SHEvideo(){
                console.log('计算',this.listCC[this.ssr].KUAN * this.listCC[this.ssr].totalT / 100);
                this.listCC[this.ssr].videoCurrent = this.listCC[this.ssr].KUAN * this.listCC[this.ssr].totalT / 100
                this.listCC[this.ssr].BFvideo = document.getElementById('BFvideo')
                this.listCC[this.ssr].BFvideo.currentTime = this.listCC[this.ssr].videoCurrent
            },
            djggg(e){
                console.log('11111111111111111111111111111111')
                let BFvideo = document.getElementById('BFvideo')
                // 鼠标在播放条上点击时进行捕获并进行处理
                /*function videoSeek(e){
                    if(video.paused || video.ended){
                        play();
                        enhanceVideoSeek(e);
                    }
                    else{
                        enhanceVideoSeek(e);
                    }

                }
                function enhanceVideoSeek(e){
                    clearInterval(progressFlag);
                    var length = e.pageX - progressWrap.offsetLeft;
                    var percent = length / progressWrap.offsetWidth;
                    playProgress.style.width = percent * (progressWrap.offsetWidth) - 2 + "px";
                    video.currentTime = percent * video.duration;
                    progressFlag = setInterval(getProgress, 60);
                }*/
                console.log('BFvideo.currentTime',BFvideo.currentTime);
                console.log('进度条span信息',e);
            },
            YCvideo(){
                this.YRvideoFo = 0
            },
            YRvideo(){
                this.YRvideoFo = 1
                /*this.KSvideo()*/
                if (this.listCC[this.ssr].filetype == 'video/webm'||this.listCC[this.ssr].filetype=='video/mp4'){
                    this.YRvideoFo = 1
                    this.listCC[this.ssr].BFvideo = document.getElementById('BFvideo')
                    let play_pause = document.querySelector('.play_pause')
                    var progress = document.querySelector('.progress')
                    var progressTimer = document.querySelector('.progress_timer')
                    var durationTimer = document.querySelector('.duration_timer')
                    this.listCC[this.ssr].totalT = this.listCC[this.ssr].BFvideo.duration
                    console.log('898989',this.listCC[this.ssr].totalT)
                    var videoDuration = formatTime(this.listCC[this.ssr].totalT)
                    this.innerTextWS = videoDuration
                    if(this.listCC[this.ssr].BFvideo.paused){
                        // 如果视频处于播放状态
                        play_pause.classList.remove('fa-pause')
                        play_pause.classList.add('fa-play')
                    }else{
                        play_pause.classList.add('fa-pause')
                        play_pause.classList.remove('fa-play')
                    }
                }
            },
            BFSStime(){

                this.$nextTick(()=>{
                    var progressTimer = document.querySelector('.progress_timer')
                    var durationTimer = document.querySelector('.duration_timer')
                    this.listCC[this.ssr].presentT = this.listCC[this.ssr].BFvideo.currentTime
                    var videoCurrent = formatTime(this.listCC[this.ssr].presentT)
                    console.log('videoObj.currentTime',videoCurrent)
                    progressTimer.innerHTML = videoCurrent
                    durationTimer.innerHTML = formatTime(this.listCC[this.ssr].BFvideo.duration)
                    var progress = document.querySelector('.progress')
                    //0.实现进度条
                    //1.获取所需元素
                    //2.根据时间来实现百分比
                    var percent = this.listCC[this.ssr].presentT/this.listCC[this.ssr].totalT*100
                    this.listCC[this.ssr].KUAN = percent
                    if (this.listCC[this.ssr].KUAN == 100){
                        this.listCC[this.ssr].KUAN = 0
                        this.listCC[this.ssr].presentT = 0
                        let play_pause = document.querySelector('.play_pause')
                        play_pause.classList.add('fa-play')
                        play_pause.classList.remove('fa-pause')
                    }
                    //     console.log('span宽度', this.listCC[this.ssr].totalT * 100);

                })
            },
            BFtime(){
                this.$nextTick(()=>{

                    // console.log('this.listCC[this.ssr].presentT',this.listCC[this.ssr].presentT)
                    //                console.log('jbsbdx')
                                   let videoObj = document.getElementById('BFvideo')

                    var progressTimer = document.querySelector('.progress_timer')
                                   var durationTimer = document.querySelector('.duration_timer')
                    //                let play_pause = document.querySelector('.play_pause')
                                   this.listCC[this.ssr].totalT = videoObj.duration
                                   var videoDuration = formatTime(this.listCC[this.ssr].totalT)
                                   durationTimer.innerHTML = videoDuration
                    progressTimer.innerHTML = formatTime(this.listCC[this.ssr].presentT)
                    // this.BFvideo.currentTime = this.listCC[this.ssr].presentT
                    /*if (this.listCC[this.ssr].presentT != 0){
                        videoObj.currentTime = this.listCC[this.ssr].presentT
                    }*/
                    //let videoObj = document.getElementById('BFvideo')
                    /*if (this.listCC[this.ssr].presentT != 0){
                        // videoObj.duration = this.listCC[this.ssr].totalT

                        videoObj.play()
                        play_pause.classList.remove('fa-play')
                        play_pause.classList.add('fa-pause')

                    }*/
                    // console.log('测试',videoObj.duration,videoObj.currentTime,)
                    this.loadings = false
                })
            },
            KSvideo(){
                this.listCC[this.ssr].BFvideo = document.getElementById('BFvideo')
                let play_pause = document.querySelector('.play_pause')
                var progress = document.querySelector('.progress')
                var durationTimer = document.querySelector('.duration_timer')
                // console.log(BFvideo)
                if(this.listCC[this.ssr].BFvideo.paused){
                    // 如果视频处于播放状态
                    this.listCC[this.ssr].BFvideo.play()
                    durationTimer.innerHTML = formatTime(this.listCC[this.ssr].BFvideo.duration)
                    if(this.listCC[this.ssr].presentT > 0){
                        this.listCC[this.ssr].BFvideo.currentTime = this.listCC[this.ssr].presentT
                    }
                    play_pause.classList.remove('fa-play')
                    play_pause.classList.add('fa-pause')
                }else{
                    this.listCC[this.ssr].BFvideo.pause()
                    play_pause.classList.add('fa-play')
                    play_pause.classList.remove('fa-pause')
                    return false;
                }
            },
            /*textTitF(){
                this.lol = 1
                let input = document.querySelector('.textTit>input')
                let icon = document.querySelector('.textTit>i')
                console.log('.textTit',icon)
                const l = input.value.length;
                input.style.width = (l+1) * 15 + "px";
                for (let i = 1501; i < 5000; i++) {
                    console.log('input.style.width == i +\'px\'',input.style.width == i +'px')
                    if (input.style.width == i +'px'){
                        input.style.width = 1500+'px'
                    }
                }
            },
            textTitR(){
                let input = document.querySelector('.textTit>input')
                input.style.width = 300 + "px";
            },
            textTit(){
                let input = document.querySelector('.textTit>input')
                let icon = document.querySelector('.textTit>i')
                console.log('.textTit',icon)
                const l = input.value.length;
                input.style.width = (l+1) * 15 + "px";
                if (input.value == ''){
                    let timestamp = (new Date()).valueOf() / 1000;

                    this.post.title = '新视频' + formatDate(timestamp, true)
                    input.style.width = 300+'px'
                    this.$message.error('标题不能为空')
                }
                for (let i = 1501; i < 5000; i++) {
                    console.log('input.style.width == i +\'px\'',input.style.width == i +'px')
                    if (input.style.width == i +'px'){
                        input.style.width = 1500+'px'
                    }
                }


            },*/
            down(indexs,iten){
                this.single(indexs,iten)
            },
            HuoQuTime(){
                this.musicList.forEach((el,le)=>{
                    let audio = new Audio(el.file)
                    audio.load()
                    audio.oncanplay = () => {
                        let io = []
                        io.push(audio.duration)

                        /*audio.duration = Math.round(audio.duration)*/
                        this.playRadioTime.push(io)
                        el.time = Math.round(audio.duration)
                            el.time = '00:'+el.time
                    }
                })
            },
            choice(item,index,id){
                console.log('经的')
                this.playChoice = index
                if (this.musicUse == true){
                    this.playChoice = index
                    this.bhu = item.code
                    this.post.soundtrack = item.code
                    console.log('选择',this.post.soundtrack)
                    this.musicUse = false
                }else if (this.musicUse == false){
                    this.playChoice = index
                    if (id == 3){
                        this.bhu = item.code
                        this.post.soundtrack = item.code
                        console.log('选择',this.post.soundtrack)
                        this.musicUse = false
                    }else {
                        this.bhu = item.code
                        this.post.soundtrack = this.musicList[0].code
                        console.log('取消',this.post.soundtrack)
                        this.musicUse = true
                    }
                }

            },
            playSwitch(iten,index,id){
                console.log('iten.file',iten.file)
                this.musicPlay = iten.file
                if (this.playMusic == true){
                    this.muPl = index
                    this.playMusic = false
                    this.$nextTick(()=>{
                        document.querySelector('#DFU').play()
                    })
                }else if (this.playMusic == false) {
                    this.muPl = index
                    if(id == 3){
                        this.playMusic == false
                        this.$nextTick(()=>{
                            document.querySelector('#DFU').play()
                        })
                    }else{
                        this.playMusic = true
                        this.$nextTick(()=>{
                            document.querySelector('#DFU').pause()
                        })
                    }
                }
            },
            boFmiusic(index,iten,id){
                this.fileMP3 = iten.file
                if (this.play == true){
                    this.ace = index
                    this.play = false
                    this.$nextTick(()=>{
                        document.querySelector('#opop').play()
                    })
                }else if (this.play == false) {
                    this.ace = index
                    if(id == 3){
                        this.play == false
                        this.$nextTick(()=>{
                            document.querySelector('#opop').play()
                        })
                    }else{
                        this.play = true
                        this.$nextTick(()=>{
                            document.querySelector('#opop').pause()
                        })
                    }
                }
            },
            acbk(){
                this.dialogVisible = true
				this.play = false
				this.ace = -1
            },

            ycDel(index) {
                if (this.listCC.length <= 1) {
                    this.$message.error('至少有一个模板')
                    this.hjk = -1
                    return
                }
                let ddrt = []
                this.listCC.forEach((el, indexs) => {
                    if (index != indexs) {
                        ddrt.push(el)
                    }
                })
                console.log('ddrtssssssss', ddrt)
                /*this.videoDEX = 0*/
                if (this.ssr != 0) {
                    this.ssr = this.ssr - 1
                }

                console.log('5656565656565', this.listCC[this.ssr].activeIndex)
                this.videoDEX = this.listCC[this.ssr].videoDEX
                this.activeIndex = this.listCC[this.ssr].activeIndex
                this.listCC = ddrt
                this.hjk = -1

            },
            copy1(){
                let ssr = this.ssr
                let data = {}
                let margin = this.OMG
                if (this.listCC.length <6){
                    data = {
                       /* let margin = this.OMG
                        this.listCC[f].post.avatarSettings.style = 'circular'
                        this.listCC[f].margin = '0 auto'
                        this.listCC[f].right = '0px'
                        this.listCC[f].left = '0px'
                        this.listCC[f].top = 'calc(100% - 50% + 2px)'
                        this.listCC[f].bottom = '0px'
                        this.listCC[f].height = 'calc(' + margin + '% - 6px)'
                        this.listCC[f].borderRadius = '100%'
                        this.listCC[f].transform = 'translateY(-53%)'
                        this.listCC[f].border = '1px solid #ddd'*/
                        pic: this.listCC[ssr].pic,
                        s_pic: this.listCC[ssr].s_pic,
                        filetype: this.listCC[ssr].filetype,
                        data_url: this.listCC[ssr].data_url,
                        left: this.listCC[ssr].left,
                        bottom:this.listCC[ssr].bottom,
                        height:this.listCC[ssr].height,
                        right:  this.listCC[ssr].right,
                        margin: this.listCC[ssr].margin,
                        top:this.listCC[ssr].top,
                        KUAN:this.listCC[ssr].KUAN,
                        videoCurrent:this.listCC[ssr].videoCurrent,
                        totalT:this.listCC[ssr].totalT,
                        presentT:this.listCC[ssr].presentT,
                        BFvideo:this.listCC[ssr].BFvideo,
                        transform: this.listCC[ssr].transform,
                        border: this.listCC[ssr].border,
                        backgrounds: this.listCC[ssr].backgrounds,
                        borderRadius: this.listCC[ssr].borderRadius,
                        activeIndex: this.listCC[ssr].activeIndex,
                        data_url: this.listCC[ssr].data_url,
                        value: this.listCC.length == 0 ? 100 : this.listCC[ssr].value,
                        scriptText: this.listCC[ssr].post.scriptText,
                        language_cn: this.listCC[ssr].language_cn,
                        gender: this.listCC[ssr].gender,
                        videoDEX: this.listCC[ssr].videoDEX,
                        name: this.listCC[ssr].name,
                        icon: this.listCC[ssr].icon,
                        active2: this.listCC[ssr].active2,
                        videoMax: this.listCC[ssr].videoMax,
                        active: this.listCC[ssr].active,
                        post: {
                            scriptText: this.listCC[ssr].post.scriptText,
                            avatarSettings: {
                                backgroundColor: this.listCC[ssr].post.avatarSettings.backgroundColor,
                                voice: this.listCC[ssr].post.avatarSettings.voice,
                                horizontalAlign: this.listCC[ssr].post.avatarSettings.horizontalAlign,
                                scale: this.listCC[ssr].post.avatarSettings.scale,
                                style: this.listCC[ssr].post.avatarSettings.style,
                                seamless: false,
                            },
                            background: this.listCC[ssr].post.background,
                            avatar: this.listCC[ssr].post.avatar,
                            backgroundSettings: {
                                videoSettings: {
                                    shortBackgroundContentMatchMode: "freeze",
                                    longBackgroundContentMatchMode: "trim"
                                }
                            }
                        }
                    }
                }else if (this.listCC.length>5){
                    this.$message.error('最多六个模板')
                    return
                }

                this.ssr = this.listCC.length

                this.listCC = this.listCC.concat(data)
                let fg = this.listCC[this.ssr]
                let gf = this.ssr

                console.log('videoDEX',this.listCC.length)
                this.color1 = this.listCC[this.ssr].backgrounds
                console.log('copy',this.ssr)
                this.single(this.ssr+1-1,[fg])
                // let tr = this.listCC.length-1

                // this.selectAll(this.listCC[this.ssr].activeIndex)
            },


            deleteItem(index) {
                this.hjk = index
            },


            XHJdel() {
                this.hjk = -1
            },
            GHJdel(){
                this.hjk = index
            },
            FHJdel() {
                setTimeout(()=>{
                    this.del = -1
                    this.hjk = -1
                },)

            },
            HJdel(index) {
                console.log('111111111111111111111111111111111111111')
                this.del = index
                if (this.listCC[this.ssr].filetype == 'video/webm'||this.listCC[this.ssr].filetype=='video/mp4') {
                    let play_pause = document.querySelector('.play_pause')
                    let c = [play_pause.classList]
                    console.log(c.indexOf('fa-pause'))
                    this.listCC[this.ssr].BFvideo.pause()
                    play_pause.classList.add('fa-play')
                    play_pause.classList.remove('fa-pause')
                    return false;
                }
            },


            butTc() {
                this.postQBRT = []
                this.listCC.forEach((oi,io)=>{
                    if (oi.post.scriptText == undefined || oi.post.scriptText == '') {
                        this.TYU = 1
                    }
                })
                if (this.TYU == 1) {
                    this.$message.error('请输入语言文本框内文字')
                    console.log(this.listCC)
                    this.TYU = 0
                    return
                }
                this.listCC.forEach((el, index) => {
                    console.log(el)
                    if (el.videoDEX == 0) {
                        el.post.background = this.backgroundGET[el.active2].data_value
                    } else {
                        el.post.background = this.tableData[el.videoMax].fileid
                    }
                    console.log(this.anmList[el.active].actor_id, el.active, 1111111111111111111)
                    /*if (this.$route.query.id != '' && this.$route.query.id != undefined && this.$route.query.id != null)*/
                    el.post.avatar = this.anmList[el.active].actor_id//演员头像ID

                    console.log('el.post.avatarSettings.scale', el.post.avatarSettings.scale)
                    el.post.avatarSettings.seamless = false//是否开启无缝、false、true
                    el.post.backgroundSettings.videoSettings.shortBackgroundContentMatchMode = 'freeze'//选项 ：freeze、loop 、slow_down
                    el.post.backgroundSettings.videoSettings.longBackgroundContentMatchMode = 'trim'//选项：trim、speed_up
                    if (el.activeIndex == '1') {
                        delete el.post.avatarSettings.backgroundColor
                    } else if (el.activeIndex == '2') {
                        el.post.avatarSettings.backgroundColor = this.listCC[index].backgrounds//头像背景色
                        delete el.post.avatarSettings.horizontalAlign
                        console.log('位置测试', el.post.avatarSettings)
                    }
                    console.log('fdfdfdfdfdfdfdfd', el)
                    this.postQBRT.push(el.post)
                    console.log('请输入语言文本框内文字', el.post.scriptText)

                })
                this.postQBRT.forEach((ee, dd) => {
                    ee.avatarSettings.scale = ee.avatarSettings.scale/100
                })
                    console.log(this.postQBRT,'this.postQBRT')
                    console.log('比例', this.postQBRT)
                    this.postZH = true
                    this.postQBD()
            },
            butTc2() {
                this.gkh = 1
                this.butTc()
            },

            postQBD() {


                this.post.input = this.postQBRT
                console.log(this.post.input, 1111111)
                // return false
                if (this.bhu != ''){
                    this.post.soundtrack = this.bhu
                }else {
                    this.post.soundtrack = 'urban'
                }

                let postS = this.post

                postS.input = JSON.stringify(postS.input)
                console.log('全部上传', postS.soundtrack)
                // return false
                /*postS.input.listCC = this.listCC*/

                let fg = JSON.stringify(this.listCC)
                if (this.countcc == 1) {
                    console.log('11111111119999999999',)
                    getUservideoUpdate({
                        id: this.dataId,
                        title: postS.title,
                        description: postS.description,
                        soundtrack: postS.soundtrack,
                        input: postS.input,
                        style_data: fg
                    }).then(res => {
                        console.log('修改成功', res)

                        this.backd == 1
                        console.log('ioooooooooooo', this.gkh == 1)
                        if (this.gkh == 1) {
                            console.log('ioooooooooooo', this.videoDEX)
                            this.$confirm('是否生成视频?', '提示', {
                                confirmButtonText: '生成',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                getuplodebj({id: this.dataId}).then(rese => {
                                    console.log('生成成功返回', rese)
                                    this.$router.push(`/videoList`)
                                    this.$message.success('视频正在生成中，请耐心等待！')
                                    this.backd == 1
                                }).catch(errore => {
                                    console.log('生成失败返回', errore)
                                    this.$message.error(errore.msg)
                                })
                                this.postZH = false
                                this.$router.push(`/videoOperate?id=${this.dataId}`)
                            }).catch(() => {

                                this.postZH = false

                                this.$router.push(`/videoOperate?id=${this.dataId}`)

                                this.$message({
                                    type: 'info',
                                    message: '已保存到草稿'
                                });
                            });
                            this.gkh = -1
                            return
                        }
                        this.$message.success('修改成功')
                    }).catch(errorcc => {
                        console.log('修改失败', errorcc)
                        this.$message.error(errorcc.msg)
                    })
                } else {

                    getvoiceadd({
                        title: postS.title,
                        description: postS.description,
                        soundtrack: postS.soundtrack,
                        input: postS.input,
                        style_data: fg
                    }).then(res => {
                        console.log(res)
                        this.post.description = '',
                            this.dataId = res.data
                        this.$router.push(`/videoOperate?id=${this.dataId}`)
                        this.countcc = 1
                        this.backd == 1
                        console.log('this.resId', this.dataId)
                        if (this.gkh === 1) {
                            this.$confirm('是否生成视频?', '提示', {
                                confirmButtonText: '生成',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                getuplodebj({id: this.dataId}).then(rese => {
                                    console.log('生成成功返回', rese)
                                    this.$router.push(`/videoList`)
                                    this.$message.success('视频正在生成中，请耐心等待！')
                                    this.backd == 1
                                }).catch(errore => {
                                    console.log('生成失败返回', errore)
                                    this.$message.error(errore.msg)
                                })
                                this.postZH = false
                                this.$router.push(`/videoOperate?id=${this.dataId}`)
                            }).catch(() => {
                                this.postZH = false

                                this.$router.push(`/videoOperate?id=${this.dataId}`)

                                this.$message({
                                    type: 'info',
                                    message: '已保存到草稿'
                                });
                            });
                            this.gkh = -1
                            return
                        }
                        this.$message.success('保存成功')
                    }).catch(error => {

                        this.get()
                        console.log(error)
                        this.$message.error(error.msg)
                        this.postZH = false
                    })
                }
                this.postQBRT.forEach((ee, dd) => {
                    ee.avatarSettings.scale = ee.avatarSettings.scale * 100
                })
            },
            /*uplode(){
              this.postQBD()
              if (this.resId == ''||this.resId == undefined){
                this.$message.error('请先保存到草稿')
                return
              }
              getuplodebj({id:this.resId}).then(res=>{
                console.log('生成成功',res)

              }).catch(error=>{
                this.$message.error('生成失败')
              })
              this.postZH = false
            },*/

            Onclick(index) {
                console.log('opopppppooopoppo', this.listCC[this.ssr].filetype)
                this.GMO = this.listCC[this.ssr].value
                this.active = index
                this.prng = index
                let ssr = this.ssr
                this.listCC[ssr].active = index
                console.log('ssr', ssr)
                let activeIndex = this.activeIndex
                let data = {}
                if (activeIndex == '1') {
                    data = {
                        pic: this.anmList[index].pic,
                        s_pic: this.anmList[index].s_pic,
                        filetype: this.listCC[ssr].filetype,
                        data_url: this.backgroundGET[0].data_url,
                        left: this.listCC.length > 0 && this.listCC[ssr].left == 'unset' ? 'unset' : '5px',
                        right: this.listCC.length > 0 && this.listCC[ssr].right == 'unset' ? 'unset' : '5px',
                        margin: this.listCC.length > 0 && this.listCC[ssr].margin == 'unset' ? 'unset' : '0 auto',
                        activeIndex: activeIndex,
                        data_url: this.listCC[ssr].data_url,
                        value: this.listCC.length == 0 ? 100 : this.listCC[ssr].value,
                        scriptText: this.listCC[ssr].post.scriptText,
                        language_cn: '',
                        gender: this.anmList[this.prng].gender,
                        videoDEX: this.listCC[ssr].videoDEX,
                        name: '',
                        icon: '',
                        KUAN:this.listCC[ssr].KUAN,
                        videoCurrent:this.listCC[ssr].videoCurrent,
                        totalT:this.listCC[ssr].totalT,
                        presentT:this.listCC[ssr].presentT,
                        BFvideo:this.listCC[ssr].BFvideo,
                        active2: this.listCC[ssr].active2,
                        videoMax: this.listCC[ssr].videoMax,
                        active: index,
                        post: {
                            scriptText: this.listCC[ssr].post.scriptText,
                            avatarSettings: {
                                backgroundColor: '',
                                voice: '',
                                horizontalAlign: this.listCC[ssr].post.avatarSettings.horizontalAlign,
                                scale: this.listCC[ssr].post.avatarSettings.scale,
                                style: 'rectangular',
                                seamless: false,
                            },
                            background: this.backgroundGET[0].data_value,
                            avatar: '',
                            backgroundSettings: {
                                videoSettings: {
                                    shortBackgroundContentMatchMode: "freeze",
                                    longBackgroundContentMatchMode: "trim"
                                }
                            }
                        }
                    }
                } else {
                    data = {
                        KUAN:this.listCC[ssr].KUAN,
                        videoCurrent:this.listCC[ssr].videoCurrent,
                        totalT:this.listCC[ssr].totalT,
                        presentT:this.listCC[ssr].presentT,
                        BFvideo:this.listCC[ssr].BFvideo,
                        pic: this.anmList[index].pic,
                        s_pic: this.anmList[index].s_pic,
                        data_url: this.backgroundGET[0].data_url,
                        left: '0px',
                        right: '0px',
                        margin: '0 auto',
                        top: 'calc(100% - 50% + 5px)',
                        transform: 'translateY(-56%)',
                        border: '1px solid #ddd',
                        backgrounds: this.color1,
                        borderRadius: '100%',
                        activeIndex: activeIndex,
                        filetype: this.listCC[ssr].filetype,
                        gender: this.anmList[this.prng].gender,
                        data_url: this.backgroundGET[0].data_url,
                        value: this.listCC.length == 0 ? 100 : this.listCC[ssr].value,
                        scriptText: this.listCC[ssr].post.scriptText,
                        language_cn: '',
                        videoDEX: this.listCC[ssr].videoDEX,
                        name: '',
                        icon: '',
                        active2: this.listCC[ssr].active2,
                        videoMax: this.listCC[ssr].videoMax,
                        active: index,
                        post: {
                            scriptText: this.listCC[ssr].post.scriptText,
                            avatarSettings: {
                                voice: '',
                                horizontalAlign: this.listCC[ssr].post.avatarSettings.horizontalAlign,
                                scale: this.listCC[ssr].post.avatarSettings.scale,
                                style: 'rectangular',
                                seamless: false,
                            },
                            background: this.backgroundGET[0].data_value,
                            avatar: '',
                            backgroundSettings: {
                                videoSettings: {
                                    shortBackgroundContentMatchMode: "freeze",
                                    longBackgroundContentMatchMode: "trim"
                                }
                            }
                        }
                    }
                }

                this.listCC.length == 0 ? this.listCC = this.listCC.concat(data) : this.listCC[ssr] = data

                this.color1 = this.listCC[this.ssr].backgrounds
                if (activeIndex == '2') {
                    this.selectAll(activeIndex)
                }
                this.squery()
            },

            /*ddcke(index){
                this.ddckes = index
            },*/
            // fdafdaf
            languageSelect(index) {
                this.genderD = index
                this.dialogVisible = false
				this.$nextTick(()=>{
					document.querySelector('#opop').pause()
				})
                this.listCC[this.ssr].language_cn = this.QBvoice[this.genderD].language_cn
                this.listCC[this.ssr].name = this.QBvoice[this.genderD].name
                this.listCC[this.ssr].icon = this.QBvoice[this.genderD].icon
                console.log('llollooololo0ol', this.QBvoice[this.genderD])
            },


            handleClose(done) {
				this.$nextTick(()=>{
					document.querySelector('#opop').pause()
				})
                done()
            },

            bctext() {
                this.listCC[this.ssr].post.scriptText = this.scriptText
                let ddr = []

                getmiuseConfig({script: this.listCC[this.ssr].post.scriptText}).then(res => {
                    let value = this.PDvoice
                    value = res.data.language
                    if (value == 'zh') {
                        this.maxlength = 1500
                        return false
                    } else if (value != 'zh') {
                        this.maxlength = 3500
                        return false
                    }
                })
                if (this.listCC[this.ssr].post.scriptText == '') {
                    this.maxlength = 3500
                    return false
                }

                /*this.voice.forEach((rt,tr)=>{
                        rt.code == 'zh'
                })

                if (this.scriptText == ddr||this.scriptText == ddr1||this.scriptText == ddr2){
                    this.maxlength = 1500
                }*/
            },
            squery() {
                if (this.scriptText == '') {
                    return
                }
                this.loadang = true
                /*this.listCC[this.ssr].scriptText = this.scriptText*/
                this.listCC[this.ssr].post.scriptText = this.scriptText
                setTimeout(() => {
                    getmiuseConfig({script: this.listCC[this.ssr].post.scriptText}).then(res => {
                        this.QBvoice = []
                        this.PDvoice = res.data.language
                        let value = this.PDvoice
                        let ssr = this.listCC[this.ssr].gender
                        this.voice.forEach(el => {
                            if (el.code == value && el.gender == ssr) {
                                this.QBvoice.push(el)

                            }
                        })
                        console.log('fdaeeg', this.QBvoice)
                        /*console.log("this.QBvoice[this.genderD].language_cn",this.QBvoice[this.genderD].language_cn)*/

                        console.log('222222222222', this.vdeiu)
                        if (this.QBvoice == '') {
                            this.listCC[this.ssr].post.avatarSettings.voice = ''

                        } else {

                            this.listCC[this.ssr].post.avatarSettings.voice = this.QBvoice[this.genderD].voice_id

                        }

                        if (this.QBvoice.length > 0) {
                            this.listCC[this.ssr].name = this.QBvoice[this.genderD].name
                            this.listCC[this.ssr].icon = this.QBvoice[this.genderD].icon
                            this.listCC[this.ssr].language_cn = this.QBvoice[this.genderD].language_cn

                        } else {
                            this.listCC[this.ssr].name = '未找到'
                            this.listCC[this.ssr].icon = '未找到'
                            this.listCC[this.ssr].language_cn = '未找到'
                        }

                        /*let ssr = this.prng*/

                        this.QBvoice.forEach((io,indexsd)=>{
                            io.dck = this.ddckes
                        })
                        console.log('搜索到的语言', this.QBvoice)
                        this.loadang = false
                    })
                })
            },


            beforeUploadVideo(file) {
                var fileSize = file.size / 1024 / 1024 < 100;
                if (['image/jpeg', 'image/png', 'image/svg+xml', 'video/mp4', 'video/webm'].indexOf(file.type) == -1) {
                    this.$message.error("请上传正确的视频格式");
                    return false;
                }
                if (!fileSize) {
                    this.$message.error("视频大小不能超过100MB");
                    return false;
                }
                this.isShowUploadVideo = false;
            },
            //进度条
            uploadVideoProcess(event, file, fileList) {
                this.videoFlag = true;
                this.videoUploadPercent = file.percentage.toFixed(0) * 1;
            },
            //上传成功回调
            handleVideoSuccess(res, file) {
                this.isShowUploadVideo = true;
                this.videoFlag = false;
                this.videoUploadPercent = 0;

                //后台上传地址
                if (res.status == 200) {
                    /*this.videoForm.showVideoPath = res.Data;*/
                    this.get()
                } else {
                    this.$message.error(res.msg);
                }
            },



            colorTo() {
                this.listCC[this.ssr].backgrounds = this.color1
                console.log('颜色',this.color1)
            },
            addMaterial() {
                if (this.listCC.length < 6) {
                    // this.$message.success('添加成功',)
                    let oo = this.listCC[this.listCC.length - 1]
                    console.log("oo", oo)
                    let data = {
                        pic: oo.pic,
                        s_pic: oo.s_pic,
                        left: oo.left,
                        right: oo.right,
                        margin: oo.margin,
                        activeIndex: '1',
                        KUAN:0,
                        videoCurrent:0,
                        totalT:0,
                        presentT:0,
                        BFvideo:null,
                        data_url: this.backgroundGET[0].data_url,
                        value: oo.value,
                        gender: '2',
                        language_cn: '',
                        videoDEX: 0,
                        name: '',
                        icon: '',
                        scriptText: '',
                        data_value: '',
                        active2: 0,
                        videoMax: 0,
                        active: oo.active,
                        //-----------------------------------------------------
                        post: {
                            scriptText: '',
                            avatarSettings: {
                                backgroundColor: this.listCC[this.listCC.length - 1].post.avatarSettings.backgroundColor,
                                voice: '',
                                horizontalAlign: this.listCC[this.listCC.length - 1].post.avatarSettings.horizontalAlign,
                                scale: this.listCC[this.listCC.length - 1].post.avatarSettings.scale,
                                style: 'rectangular',
                                seamless: false,
                            },
                            background: this.backgroundGET[0].data_value,
                            avatar: oo.post.avatar,
                            backgroundSettings: {
                                videoSettings: {
                                    shortBackgroundContentMatchMode: "freeze",
                                    longBackgroundContentMatchMode: "trim"
                                }
                            }
                        }
                    }
                    this.ssr = this.listCC.length


                    this.listCC = this.listCC.concat(data)
                    this.activeIndex = '1'
                    this.scriptText = this.listCC[this.ssr].post.scriptText

                    this.htoh = this.listCC[this.ssr].post.avatarSettings.scale
                    console.log()
                    console.log('添加失败，素材最多6个', 'kk',this.listCC[this.ssr].post.avatarSettings.scale)
                } else if (this.listCC.length >= 6) {
                    this.$message.error('添加失败，素材最多6个')
                }

            },
            gb1(item) {
                console.log('ooooooooooooooo', item)
            },
            back() {
                if (this.backd == 0) {
                    this.$confirm('您是否退出?(请确认是否保存)', '提示', {
                        confirmButtonText: '确认',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$router.push('/videoList')
                    }).catch(() => {
                        return
                    })
                } else {
                    this.$router.push('/videoList')
                }
            },
            dClick(index) {
                this.listCC[this.ssr].videoDEX = 0
                let ssr = this.ssr
                this.active2 = index
                this.listCC[ssr].active2 = index
                this.listCC[ssr].data_url = this.backgroundGET[index].data_url
                this.listCC[ssr].post.background = this.backgroundGET[index].data_value
                console.log(this.listCC[ssr].post.background);
                console.log('listCC', this.listCC, this.listQB)
            },
            imgLoad(msg) {
                console.log("imgLoad");
                console.log(msg);
            },
            realTime(data) {
                console.log("realTime:", data);
                this.previews = data;
            },
            rigBtn1() {

            },
            selectAll(index) {
                console.log('ppoppppppppp', index)
                this.activeIndex = index
                if (index == 1) {
                    let f = this.ssr ? this.ssr : 0
                    this.listCC[f].post.avatarSettings.style = 'rectangular'
                    this.listCC[f].margin = '0 auto'
                    this.listCC[f].right = '0px'
                    this.listCC[f].left = '0px'
                    this.listCC[f].top = 'unset'
                    this.listCC[f].bottom = '0px'
                    this.listCC[f].height = '100%'
                    this.listCC[f].borderRadius = 'unset'
                    this.listCC[f].transform = 'unset'
                    this.listCC[f].border = 'unset'
                    this.listCC[f].activeIndex = '1'
                    this.listCC[f].backgrounds = this.color1
                    this.butBtn()
                    niu.headPortraitClear(document.querySelector(".img2"), document.querySelector(`.img-${f}`), this.color1)
                } else {
                    this.dis = true
                    let f = this.ssr ? this.ssr : 0
                    let margin = this.OMG
                    this.listCC[f].post.avatarSettings.style = 'circular'
                    this.listCC[f].margin = '0 auto'
                    this.listCC[f].right = '0px'
                    this.listCC[f].left = '0px'
                    this.listCC[f].top = 'calc(100% - 50% + 2px)'
                    this.listCC[f].bottom = '0px'
                    this.listCC[f].height = 'calc(' + margin + '% - 6px)'
                    this.listCC[f].borderRadius = '100%'
                    this.listCC[f].transform = 'translateY(-53%)'
                    this.listCC[f].border = '1px solid #ddd'
                    this.listCC[f].activeIndex = '2'
                    this.color1 = '#409EFF'
                    this.listCC[f].backgrounds = this.color1

                    niu.headPortrait(document.querySelector(".img2"), document.querySelector(`.img-${f}`), margin, this.color1)
                }
            },
            circular() {
                console.log(111)
                let btn = document.querySelector(".img2")
                let f = this.ssr
                let btn_1 = document.querySelector(`.img-${f}`)
                btn.style.width = '200px'
                btn_1.style.width = 200 / 4 + 'px'
                btn.style.marginLeft = '100px'
                btn_1.style.marginLeft = 100 / 4 + 'px'
            },
            leftBtn() {
                this.Dleft = 1
                this.Dcenter = 0
                this.Dright = 0
                this.listCC[this.ssr].post.avatarSettings.horizontalAlign = 'left'
                console.log('现1在位置在', this.listCC[this.ssr].post.avatarSettings.horizontalAlign)
                let f = this.ssr
                console.log(f, this.listCC[f].value)
                let margin = this.listCC[f].value
                this.listCC[f].margin = 'unset'
                this.listCC[f].right = 'unset'
                this.listCC[f].left = '5px'
                this.htoh = this.listCC[this.ssr].post.avatarSettings.scale
                niu.leftBtn(document.querySelector(".img2"), document.querySelector(`.img-${f}`), margin, f)
            },
            butBtn() {
                this.Dleft = 0
                this.Dcenter = 1
                this.Dright = 0
                this.listCC[this.ssr].post.avatarSettings.horizontalAlign = 'center'
                console.log('现在位置在', this.listCC[this.ssr].post.avatarSettings.horizontalAlign)
                let f = this.ssr
                console.log(f, this.listCC[f].value)
                let margin = this.listCC[f].value
                this.listCC[f].margin = '0 auto'
                this.listCC[f].right = '0px'
                this.listCC[f].left = '0px'
                this.htoh = this.listCC[this.ssr].post.avatarSettings.scale
                niu.amarginCon(document.querySelector(".img2"), document.querySelector(`.img-${f}`), margin, f)
            },
            rigBtn() {
                this.Dleft = 0
                this.Dcenter = 0
                this.Dright = 1
                this.listCC[this.ssr].post.avatarSettings.horizontalAlign = 'right'
                console.log('现在位置在', this.listCC[this.ssr].post.avatarSettings.horizontalAlign)
                let f = this.ssr
                console.log(f, this.listCC[f].value)
                let margin = this.listCC[f].value
                this.listCC[f].margin = 'unset'
                this.listCC[f].left = 'unset'
                this.listCC[f].right = '5px'
                this.htoh = this.listCC[this.ssr].post.avatarSettings.scale
                niu.rightBut(document.querySelector(".img2"), document.querySelector(`.img-${f}`), margin, f)
            },
            changeTabs() {
                this.OMGDER()
                console.log('bvvvbbbvvvbbbvvvb', this.htoh, this.OMG)
                let f = this.ssr
                console.log(this.listCC[f])
                this.listCC[f].value = this.OMG
                this.listCC[f].post.avatarSettings.scale = this.htoh
                console.log('this.htoh', this.listCC[f].post.avatarSettings.scale)
                console.log(JSON.stringify(this.listCC))
                if (this.activeIndex == '2') {
                    this.listCC[f].backgrounds = this.color1
                }
                // this.listCC[ssr].value = this.htoh
                // niu.Slidingsize(document.querySelector(".img2"),document.querySelector(`.img-${f}`),this.htoh)
                // console.log(this.listCC)
            },

            get() {
                let timestamp = (new Date()).valueOf() / 1000;

                this.post.title = '新视频' + formatDate(timestamp, true)
                this.loading = true

                getAvatars({limit: 1000,}).then(res => {

                    this.anmList = res.data.list
                    this.countcc = 0
                    console.log('演员列表', this.anmList)
                })
                getvoicebj({limit: 1000}).then(res => {
                    console.log('背景列表', res)
                    this.backgroundGET = res.data.list
                    /*if (this.count = 0){*/
                    this.listCC[0].data_url = this.backgroundGET[0].data_url
                    this.listCC[0].post.background = this.backgroundGET[0].data_value
                    console.log('this.listCC[0].post.background', this.listCC[0].post.background)
                    /*}*/

                    setTimeout(() => {
                        this.loading = false
                    }, 1000)
                })
                getXxxfile({limit: 1000}).then(res => {          //上传（视频/图片）列表
                    console.log('上传（视频/图片）列表', res)
                    this.tableData = res.data.list
                    this.total = res.data.count
                    getvoiceConfig({limit: 1000}).then(res => {
                        console.log('声音列表', res)     // 声音列表
                        this.voice = res.data.list
                        this.butBtn()
                        this.anmList.forEach((gb, fh) => {
                            /*console.log('oppppppppppppppppppppppppoooooooo',this.$route.query.actor_id)*/
                            if (gb.actor_id == this.$route.query.actor_id) {
                                console.log('oppppppppppppppppppppppppoooooooo', fh)
                                console.log('oppppppppppppppppppppppppoooooooo', gb.actor_id)
                                this.Onclick(fh)
                            }
                        })
                    })
                }).catch(err => {
                    console.log(err)
                })
                getUservideoInfo({id: this.$route.query.id}).then(resv => {
                    console.log('编辑获取数据成功返回', resv)
                    /*this.listCC = resv.data.style_data*/
                    this.listCC = JSON.parse(resv.data.style_data)
                    this.countcc = 1
                    this.scriptText = this.listCC[this.ssr].post.scriptText
                    this.post.title = resv.data.title
                    this.post.description = resv.data.description
                    this.post.soundtrack = resv.data.soundtrack
                    console.log('resv.data.soundtrack',resv.data.soundtrack);
                    this.musicList.forEach((po,lo)=>{
                        if (po.code == this.post.soundtrack){
                            this.musicUse = true
                            this.choice(po,lo,1)

                        }
                    })
                    this.dataId = this.$route.query.id
                    this.activeIndex = this.listCC[this.ssr].activeIndex
                    this.htoh = this.listCC[this.ssr].post.avatarSettings.scale * 100
                    this.listCC.forEach((oo, pp) => {
                        oo.post.avatarSettings.scale = oo.post.avatarSettings.scale * 100
                    })
                    this.color1 = this.listCC[this.ssr].backgrounds
                    /*this.selectAll(this.activeIndex)*/
                    console.log('this.post', this.listCC[this.ssr].post.avatarSettings.scale)
                    this.selectAll(this.listCC[0].activeIndex)
                }).catch(errorss => {
                    console.log('编辑获取数据失败返回', errorss)

                })
                if (this.$route.query.id != '' && this.$route.query.id != undefined && this.$route.query.id != null) {
                    console.log('不为空', this.$route.query)
                    console.log('不为空', this.listCC[0])
                    let ddr = this.$route.query.data
                    this.listCC[0].pic = this.$route.query.pic
                    this.listCC[0].s_pic = this.$route.query.s_pic
                    this.listCC[0].left = '0px'
                    this.listCC[0].right = '0px'
                    this.listCC[0].videoDEX = 0
                    this.listCC[0].KUAN = 0
                    this.listCC[0].videoCurrent = 0
                    this.listCC[0].totalT = 0
                    this.listCC[0].presentT = 0
                    this.listCC[0].BFvideo = null
                    this.listCC[0].margin = '0 auto'
                    this.listCC[0].activeIndex = '1'
                    this.listCC[0].data_url = ''
                    this.listCC[0].value = 100
                    this.listCC[0].gender = this.$route.query.gender
                    this.listCC[0].scriptText = ''//输入框内文字
                    this.listCC[0].language_cn = ''
                    this.listCC[0].name = ''
                    this.listCC[0].icon = ''
                    this.listCC[0].data_value = ''
                    this.listCC[0].active2 = 0
                    this.listCC[0].videoMax = 0
                    this.listCC[0].active = 0
                    //--------------

                    this.listCC[0].post.scriptText = ''

                    this.listCC[0].post.avatarSettings.backgroundColor = ''
                    this.listCC[0].post.avatarSettings.voice = '',
                        this.listCC[0].post.avatarSettings.horizontalAlign = ''
                    this.listCC[0].post.avatarSettings.scale = 100
                    this.listCC[0].post.avatarSettings.style = 'rectangular'
                    this.listCC[0].post.avatarSettings.seamless = false

                    this.listCC[0].post.background = ''
                    this.listCC[0].post.avatar = this.$route.query.actor_id
                    this.listCC[0].post.backgroundSettings.videoSettings.shortBackgroundContentMatchMode = "freeze"
                    this.listCC[0].post.backgroundSettings.videoSettings.longBackgroundContentMatchMode = "trim"


                } else {
                    console.log('为空')
                }

            },
            getMiuse(){
                getbjMiuse().then(res=>{
                        console.log('背景音乐列表获取成功',res);
                        this.musicList = res.data.list
                        console.log('背景音乐列表',this.musicList);
                        /*code: "urban"
                        file: "https://app.facemake.cn/static/soundtrack/urban.wav"
                        id: "12"
                        name: "城市风"*/


                        this.HuoQuTime()

                    }).catch(error=>{
                })
            },
            OMGDER() {
                if (this.htoh === 0) {
                    this.OMG = 24.5
                } else if (this.htoh === 5) {
                    this.OMG = 31.6
                } else if (this.htoh === 10) {
                    this.OMG = 35.2
                } else if (this.htoh === 15) {
                    this.OMG = 38.8
                } else if (this.htoh === 20) {
                    this.OMG = 42.4
                } else if (this.htoh === 25) {
                    this.OMG = 46
                } else if (this.htoh === 30) {
                    this.OMG = 49.6
                } else if (this.htoh === 35) {
                    this.OMG = 53.2
                } else if (this.htoh === 40) {
                    this.OMG = 56.8
                } else if (this.htoh === 45) {
                    this.OMG = 60.4
                } else if (this.htoh === 50) {
                    this.OMG = 64
                } else if (this.htoh === 55) {
                    this.OMG = 67.6
                } else if (this.htoh === 60) {
                    this.OMG = 71.2
                } else if (this.htoh === 65) {
                    this.OMG = 74.8
                } else if (this.htoh === 70) {
                    this.OMG = 78.4
                } else if (this.htoh === 75) {
                    this.OMG = 82
                } else if (this.htoh === 80) {
                    this.OMG = 85.6
                } else if (this.htoh === 85) {
                    this.OMG = 89.2
                } else if (this.htoh === 90) {
                    this.OMG = 92.8
                } else if (this.htoh === 95) {
                    this.OMG = 96.4
                } else if (this.htoh === 100) {
                    this.OMG = 100
                }
                this.listCC[this.ssr].value = this.OMG
            },
        }
    }
</script>

<style scoped lang="scss">
    .jshowsss{
        border: 2px solid gainsboro;
        border-radius: 10px;padding: 10px;margin: 10px;position: relative

    }
    .jshowsss:hover{
        border: 2px solid rgb(159,179,200);
    }
    .jshow{
        display: block;
    }

    .elcc {
        background: rgb(40, 51, 65);
        color: white;
    }

    .kk {
        border: 2px solid dodgerblue;
        border-radius: 5px;
    }

    .ll {
        border: 2px solid black;
        border-radius: 5px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .dic {
        width: 100%;
        height: 100px;
        line-height: 100px;
    }

    html {
        background: rgb(40, 51, 65);
        width: 100%;
        height: 100%;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #app {
        font-family: Helvetica, sans-serif;
        text-align: center;
        width: 100%;
        height: 100%;
        background: rgb(39, 51, 65);
    }

    .imgbox {
        width: 538px;
        height: 302px;
        position: relative;
        margin: 0 auto;
        margin-top: 30px;
    }

    .img-box {
        width: 150px;
        height: 100px;
        position: relative;
    }

    .img-box :hover {
        cursor: grab;

    }

    .img-box :active {
        cursor: grabbing;
    }

    .gtgtG {
        position: absolute;
        left: 40px;
        top: 20px;
    }

    .gtgtG :hover {
        background: url("../../assets/img/liu.png");
        position: absolute;
        left: 40px;
        top: 20px;
    }

    .img1 {
        height: 1080px;
        width: 1920px;
        transform: scale(0.293);
    }

    .imgs-1 {
        width: 150px;
        height: 100px;
    }

    .img2 {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0 auto;
        height: 100%;
    }

    .list_box {
        height: 600px;
    }

    .list_box .active {
        border: 2px solid rgb(59, 159, 248) !important;
        margin-right: 8px !important;
    }

    .list {
        margin-right: 8px;
        cursor: pointer;
    }

    .list_box .list img {
        width: 100px;
        border: 2px solid black;
    }

    .gtr > > > .el-textarea__inner {
        display: block;
        resize: vertical;
        padding: 5px 15px;
        line-height: 1.5;
        box-sizing: border-box;
        width: 100%;
        height: 270px;
        font-size: inherit;
        color: #606266;
        background-color: #FFF;
        background-image: none;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
        transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
    }

    .kl {
        border: 2px solid rgb(59, 159, 248);
        border-radius: 8px;
    }

    .lk {
        border: 2px solid black;
        border-radius: 8px;
    }

    * {
        outline: none;
    }

    .textTit {
        width: 300px !important;
    }

    #ddk {
        margin-top: 4px;
        width: 40px;
        height: 40px;
    }

    #ddk:hover {
        border-radius: 50%;
        background: rgb(47, 59, 72);
    }

    .jkw {
        color: white;
    }

    .jwk {
        color: rgb(100, 120, 140)
    }

    /*#829AB1*/
    .koll {
        position: absolute;
        bottom: 73px;
        left: 129px;
        z-index: 1111;
        background: url(../../assets/img/sandian.png);
        background-size: cover;
        height: 20px;
        display: block;
        width: 20px;
        cursor: pointer !important;
    }

    .avatarUpload {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 100px;
    }

    .backgroundGETLIST {
        width: 100px;
        display: flex;
        float: left;
        margin: 0 5px 0 5px;
        cursor: pointer;
    }
    .Mider{
        position: absolute;right: 10px;top: 8px;width: 30px;height: 30px;margin-top: 5px;
    }
    .Mider:hover{
        background: rgb(245,245,245);

        border-radius: 50%
    }
    .fa-pause:before {
        content: "\f04c";
        margin-right: 4px;
    }
    .bjMusic{
        height: 40px;
        border-radius: 8px;
        padding: 8px 12px 8px 12px;
        margin-bottom: 10px;display: flex;position: relative;
        background: rgb(56,67,80);
    }
    .muse{
        position: absolute;right: 12px;line-height: 40px;
        .button2{
            height: 30px;width: 56px;background: rgb(56,67,80);
            border: 1px solid black;border-radius: 5px;
            padding: 0 4px;color: white;
            cursor: pointer
        }
        .button3{
            height: 30px;width: 56px;background: rgb(56,67,80);
            border: 1px solid black;border-radius: 5px;
            padding: 0 4px;color: white;
            cursor: pointer
        }
        .button1{
            height: 30px;width: 56px;background: rgb(59,159,248);
            border: 1px solid black;border-radius: 5px;
            padding: 0 4px;color: white;
            cursor: pointer
        }
        .PlayVB{

            .Playi1{

            }
            .Playi1{

            }
            .Playi1{

            }

        }
    }
    .addMB{
        line-height: 100px;cursor:pointer
    }
    .addMB:hover{
        background: rgb(71,100,129);
        color: white;
        height: 100px;
        font-size: 35px;
        div{
            margin-left: 6px;
            margin-top: 20px;
        }
        span{
            font-size: 0;
        }
    }
    .box-card{
        margin-top: 20px;width: 340px;height: 90px
    }
    .Del1 :hover{
        background: rgb(240,244,248);
    }
    .Del2 :hover{
        background: rgb(240,244,248);
    }
    .timer{
        font-size: 12px;
        position: absolute;
        right: 20px;
        color: white;
        top: 7px;
    }
    .progress{
        display: block;
        width:                                                                            0%;
        height: 1px;
        background: white;
        position: relative;
    }
</style>

