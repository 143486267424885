<template>
<div>
	<el-container style="height: 100vh;">
	    <el-aside width="200px">
			<el-header height="120px" style="border-right: 1px solid #ddd;background: #273341;">

				<div class="imgtoplog">
					<el-image
						style="width: 60px; height: 60px;border-radius: 50%;"
						:src="url" 
					>
					</el-image>
					<div class="titletip">
						视频创作中心
					</div>
				</div>

			</el-header>
			<el-menu :default-openeds="['1', '4']" @select="handleSelect">
				<el-menu-item v-for="(item,indexa) in Menu" :class="{'is-active':item.href === navhref}" :index="item.href" :key="indexa">
					  <i class="fa" :class="item.ico"></i>
					  <span slot="title">{{item.name}}</span>
				</el-menu-item>
			</el-menu>
		</el-aside>
		<el-container>
			<el-header style="border-bottom:2px solid #DCDCDC ;">
				<div class="userinfoimg">
					<div class="demo-avatar">
							<div class="active">
								<div class="img">
									<el-avatar :size="45" :src="(user.userpic!==''?user.userpic:avatarimg)"></el-avatar>
									<div class="name">{{user.nickname}}</div>
								</div>
								<div class="upx">
									
									<div class="list">
										<router-link to="/dataModification" title="密码修改">
											<!-- <i class="fa fa-sign-out"></i> -->
											<span>密码修改</span>
										</router-link>
									</div>
									
								</div>
							</div>
							<router-link to="/login?clear=1" title="退出">
								<div class="loginout">
									<i class="fa fa-sign-out"></i>
									<span>退出</span>
								</div>
							</router-link>
					</div>
				</div>
			</el-header>
			<el-main>
				<router-view/>
			</el-main>
		</el-container>
		
	</el-container>
</div>
</template>

<script>
	import { getUserinfo } from '@/api/member.js';
	import { mapState, mapMutations, mapActions } from 'vuex'
    export default {
        name: "index",
		data(){
			return {
				info:'',
				url:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				avatarimg:require('@/assets/img/touxiang.jpeg'),
				Menu:[
					{name:'主页控制台',href:"/index",ico:'fa-tachometer'},
					{name:'视频列表',href:"/videoList",ico:'fa-film'},
					{name:'素材列表',href:"/materialList",ico:'fa-picture-o'},
					{name:'人物头像',href:"/performerList",ico:'fa-street-view'},
					{name:'资料修改',href:"/dataModification",ico:'fa-vcard-o'},
					{name:'充值中心',href:"/recharge",ico:'fa-credit-card'},
				],
				navhref:this.$cookies.isKey('nav')?this.$cookies.get('nav'):''
			};
		},
		computed: {
		  ...mapState({
		    user: state => state.member.storeInfo,
		  }),
		},
		created() {
			// this.$cookies.set("user_session","25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX")
			this.getUserInfo()
			if(this.$cookies.isKey('nav')){
				this.$router.push({
				    path: this.navhref,
				})
			}
		},
		methods:{
			...mapMutations({
			  setSaveStoreInfo:'saveStoreInfo'
			}),
			getUserInfo(){
				getUserinfo().then(res=>{
					this.setSaveStoreInfo(res.data)
				}).catch(err => {
					console.log(err)
				})
			},
			handleSelect(key, keyPath) {
				this.navhref=''
				this.$cookies.set('nav',key,"0")
				this.$router.push({
			        path: key,
			        params: {data: 'query' }
			      })
			}
		}
    }
</script>

<style scoped lang="scss">
	a{
		text-decoration: none;
	}
	.el-menu{
		height: calc(100% - 120px);
		background: #273341;
		overflow-y: auto;
		.el-menu-item {
		    font-size: 14px;
		    cursor: pointer;
			color: #FFFFFF;
			border-left: 3px solid #273341;
			i{
				color: #FFFFFF;
			}
			&:focus{
				outline: 0;
				background-color: #ecf5ff;
				color: #606266;
				border-left: 3px solid #606266;
				i{
					color: #606266;
				}
			}
			&:hover{
				outline: 0;
				background-color: #ecf5ff;
				color: #606266;
				border-left: 3px solid #606266;
				i{
					color: #606266;
				}
			}
		}
		.el-menu-item.is-active {
		    color: #606266;
		    border-left: 3px solid #606266;
		    background: #ecf5ff;
			i{
				color: #606266;
			}
		}
		// .el-menu-item.loginout {
		//     position: absolute;
		//     bottom: 0;
		//     left: 0;
		//     right: 0;
		// }
	}
.el-aside,.el-main,.el-menu{
	overflow-x: hidden;
	scrollbar-color:  #C4C6C6 #EEEEEE;  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
	scrollbar-width: none;  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
	-ms-overflow-style:none;  /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */

      /* 以下是chrome浏览器自定义滚动条样式方式 */
      &::-webkit-scrollbar {/*滚动条整体样式*/
            width: 0px;     /*高宽分别对应横竖滚动条的尺寸*/
            height: 0px;
      }
      &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
            border-radius: 3px;
            -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
            background: #C4C6C6;
    }
      &::-webkit-scrollbar-track {/*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
            border-radius: 3px;
            background: #EEEEEE;
      }
}

.userinfoimg {
    float: right;
    // width: 214px;
	.demo-avatar {
	   height: 58px;
	   line-height: 58px;
	   display: flex;
	   justify-content: flex-end;
	   align-items: center;
	   .active {
	       position: relative;
		   .upx {
		       position: absolute;
		       top: 100%;
		       width: 120px;
			   display: none;
			   .list {
				   a{
					   display: block;
					   background: #273341;
					   height: 40px;
					   line-height: 40px;
					   padding: 0 12px;
					   color: #FFFFFF;
				   }
			   }
		   }
		   &:hover .upx{
			   display: block;
		   }
	   }  
	   .img {
	       display: flex;
	       justify-content: flex-start;
	       align-items: center;
	       margin-right: 22px;
		   .name {
		       margin: 0px 0 0 8px;
		   }
	   }
	   .loginout {
	       text-align: right;
		   i {
		       font-size: 26px;
			   color: #273341;
		   }
		   span {
		       color: #000;
		   }
	   }
	}
}


.imgtoplog {
    text-align: center;
    padding-top: 12px;
	.el-image {
	    margin-bottom: 6px;
	}
	.titletip {
	    font-size: 22px;
	    font-weight: bold;
		color: #fff;
	}
}

</style>
