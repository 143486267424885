import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueMeta from 'vue-meta';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/font-awesome.css'
import './assets/css/css.css'
import VueCookies from 'vue-cookies'
import VueCropper from 'vue-cropper'
/*import FontAwesomeSass from 'font-awesome-sass'*/

Vue.use(VueCropper)
/*Vue.use(FontAwesomeSass)*/

axios.defaults.baseURL='https://app.facemake.cn'

Vue.prototype.$axios = axios
Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.use(VueMeta, {
	// 定义组件中的数据对象
	keyName: 'head',
});

Vue.directive('focus', {
  inserted (el, binding, vnode) {
    // 聚焦元素
	el.querySelector('input').focus()
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
