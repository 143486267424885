import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as getters from './getter'

import member from './modules/member'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  modules: {
    member
  },
  getters,
  plugins: [
    createPersistedState({
      key: 'uivideo',
      paths: ['member']
    })
  ]
})
