<template>
	<el-container>
		<el-header style="border-bottom: 1px solid #ddd;">
			<el-col :span="12">
				<div class="video">
					人物头像
				</div>
			</el-col>
			<el-col :span="12">
<!-- 				<div class="videobut">
					 <el-button type="primary">创建新视频</el-button>
				</div> -->
			</el-col>
		</el-header>
		<el-main>
			<div class="kuan">
				<div class="list clear" >
					<el-col :span="6" v-for="(item,index) in AvatarsList" :key="index">
						<div class="con">
							<el-image :src="item.pic" lazy fit="scale-down" style="height:100%;width:100%">
								<div slot="error" class="image-slot">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
							<div class="word-flex word-flex-between">
								<div class="list-name">{{item.name}}</div>
								<div class="list-but" @click="toCreatorVideo(item)">
									<span>创建视频</span><i class="fa fa-plus-square"></i>
								</div>
							</div>
						</div>
					</el-col>
				</div>
				<div class="pagination">
					<div class="block">
					  <el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="form.page"
						:page-sizes="[12,24,36,48,60]"
						:page-size="form.limit"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total">
					  </el-pagination>
					</div>
				</div>
			</div>
		</el-main>
	</el-container>
</template>

<script>
	import { getAvatars } from '@/api/member.js';
	import { formatDate } from '@/util/commons'
    export default {
        name: "performerList",
		head: {
		    title: "演员列表",
		},
		data() {
			return {
				AvatarsList:[],
				form:{
					limit:60,
					page:1
				},
				total:0
			}
		},
		created() {

		},
		mounted() {
			this.getAvatars()
		},
		methods: {
			formatDate,
			handleSizeChange(val) {
				this.form.page = 1
				this.form.limit = val
				this.getAvatars()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.form.page = val
				this.getAvatars()
				console.log(`当前页: ${val}`);
			},
			// 跳转到视频创建页面
			toCreatorVideo(data){
				console.log('视频创建页面',data)
				this.$router.push({ name: 'videoOperate' ,query:{id:data.id,s_pic:data.s_pic,pic:data.pic,gender:data.gender,actor_id:data.actor_id}})
			},
			getAvatars(){
				const loading = this.$loading({
				          lock: true,
				          text: 'Loading',
				          spinner: 'el-icon-loading',
				          background: 'rgba(0, 0, 0, 0.7)'
				        });
				getAvatars(this.form).then(res=>{
					this.AvatarsList = res.data.list
					this.total = res.data.count
					loading.close();
				}).catch(err=>{
					loading.close();
					console.log(err.msg)
				})
			}
		}
    }
</script>

<style lang="scss" scoped>
.video {
    font-size: 28px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
}
.videobut {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
}
.pagination {
    margin-top: 16px;
    text-align: center;
}

.list .el-col {
	.con {
	    margin: 10px 30px;
		.el-image{
			background: #F0F3F7;
			border-radius: 10px;
		}
		.list-name {
		    text-align: center;
		    font-size: 16px;
		    margin: 10px 8px;
		}
		.list-but {
			margin-right: 8px;
			cursor: pointer;
			span {
			    display: inline-block;
			    vertical-align: middle;
			    margin-right: 6px;
			}
			.fa.fa-plus-square {
			    vertical-align: middle;
			    display: inline;
			}
		}
	}
}
</style>
