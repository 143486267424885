<template>
    <el-container>
        <el-header style="border-bottom: 1px solid #ddd;">
            <el-col :span="12">
                <div class="video">
                    视频列表
                </div>
            </el-col>
            <el-col :span="12">
                <div class="videobut">
                    <el-button type="primary" @click="newViode">创建新视频</el-button>
                </div>
            </el-col>
        </el-header>
        <el-main>
            <el-table
                    :data="tableData"
                    v-loading="loading"
                    :row-style="{height:'80px'}"
                    style="width: 100%">
                <el-table-column label="视频" width="100">
                    <template slot-scope="scope">
                        <div class="img-tip" style="position: relative">
                            <div class="img-background"
                                 :style="{backgroundImage:'url('+scope.row.background+')',backgroundSize: 'cover',backgroundPosition: 'center center',backgroundRepeat: 'no-repeat'}"
                                 style="display: inline-flex;">
                                <el-image style="border-radius: 5px" :src="scope.row.pic" :lazy="true" width="100%">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                            </div>
                            <el-progress style="position: absolute;left: 15px;top: 15px;background: rgb(72,98,122);opacity: 0.9;color: white;border-radius: 5px" v-if="scope.row.status == 1"
                                         color="rgb(129,255,203)" :width="50" type="circle" :percentage="scope.row.getTime"></el-progress>
                            <div class="jss596" v-if="scope.row.background">
                                <svg width="7" height="50" viewBox="0 0 7 50" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.0737374 49.8776C4.16521 47.966 7.00008 43.814 7.00008 38.9998V10.9998C7.00008 6.18572 4.16525 2.03371 0.0738243 0.12207C2.48589 2.31702 3.99994 5.4816 3.99994 8.99981V40.9998C3.99994 44.5181 2.48586 47.6827 0.0737374 49.8776Z"
                                          fill="#D1D7DD"></path>
                                </svg>
                                <svg width="5" height="44" viewBox="0 0 5 44" fill="none" style="margin-left: -0.5px;">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.425292 43.4278C3.21146 41.2303 4.99999 37.8242 4.99999 34.0004V10.0004C4.99999 6.17726 3.21217 2.77173 0.426959 0.574218C2.03829 2.61703 2.99985 5.1962 2.99985 7.99996V36C2.99985 38.8047 2.03763 41.3847 0.425292 43.4278Z"
                                          fill="#EFF1F3"></path>
                                </svg>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="标题">
                    <template slot-scope="scope">
                        <div v-if="rowTitle != scope.row.id" @click="aboutTitle(scope.row)">{{ scope.row.title }} <i
                                class="el-icon-edit"></i></div>
                        <div v-else>
                            <el-input v-focus v-model="scope.row.title" @keyup.enter.native="aboutTitleTo($event)"
                                      @blur="aboutTitleTo($event)" placeholder="请再次输入标题"></el-input>
                        </div>
                    </template>
                </el-table-column>

                <!-- 				<el-table-column
                                    prop="description"
                                    label="描述">
                                </el-table-column> -->

                <el-table-column label="文件状态" width="100">
                    <template slot-scope="scope">
                        <el-tag type="info" v-if="scope.row.status == 0">草&nbsp;稿</el-tag>
                        <el-tag type="warning" v-else-if="scope.row.status == 1">生成中</el-tag>
                        <el-tag type="success" v-else-if="scope.row.status == 2">已完成</el-tag>
                    </template>
                </el-table-column>

                <!-- 				<el-table-column label="文件下载" width="160">
                                  <template slot-scope="scope">
                                       <el-button v-if="scope.row.status == 2" type="primary" @click="handleToDow(scope.row)" icon="fa fa-cloud-download">下载视频</el-button>
                                  </template>
                                </el-table-column> -->

                <el-table-column label="添加时间" width="200">
                    <template slot-scope="scope">
                        <div>{{ formatDate(scope.row.add_time,true) }}</div>
                    </template>
                </el-table-column>

                <el-table-column label="操作" width="260" fixed="right">
                    <template slot-scope="scope">
                        <el-button
                                v-if="scope.row.status == 1"
                                size="mini"
                                disabled
                        >查看
                        </el-button>

                        <el-button
                                v-if="scope.row.status == 2"
                                size="mini"
                                @click="handleToDow(scope.$index, scope.row)">下载
                        </el-button>
                        <el-button
                                v-if="scope.row.status == 0"
                                size="mini"
                                @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>

                        <el-button
                                size="mini"
                                type="success"
                                @click="handleCopydata(scope.$index, scope.row)">复制
                        </el-button>

                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <div class="block">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="form.page"
                            :page-sizes="[10,20,50,100, 200]"
                            :page-size="form.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </div>
        </el-main>
    </el-container>
</template>

<script>
    import {getUservideo, getCopydata, getUservideoDelete, getUservideoInfo, getUservideoUpdate} from '@/api/member.js';
    import {formatDate} from '@/util/commons'

    export default {
        name: "videoList",
        head: {
            title: "视频列表",
        },
        data() {
            return {
                form: {
                    limit: 10,
                    page: 0,
                    title: '',
                    status: '',
                    description: ''
                },
                tableData: [],
                total: 0,
                loading: false,
                rowTitle: '',
                rowTitleText: '',
                time: {},
                yu: []
            }
        },
        created() {

        },
        watch: {
            tableData: {
                deep: true,
                handler() {
                    // this.getVideoList()
                    this.timer()
                },
            }
        },
        mounted() {
            this.getVideoList()
            this.ace()

        },
        methods: {
            formatDate,
            aboutTitle(e) {
                this.rowTitle = Number(e.id)
                this.rowTitleText = e.title
            },
            aboutTitleTo(event) {

                if (this.rowTitleText == event.target.value) {
                    this.rowTitle = ''
                    return false
                }

                getUservideoUpdate({id: Number(this.rowTitle), title: event.target.value}).then(res => {
                    console.log(res)
                    this.$message.success(res.msg);
                    this.rowTitle = ''
                    this.getVideoList()
                }).catch(err => {
                    this.$message.error(err.msg);
                    console.log(err)
                })
            },
            newViode() {
                this.$router.push('/videoOperate')
            },
            handleSizeChange(val) {
                this.form.page = 1
                this.form.limit = val
                this.getVideoList()
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.form.page = val
                this.getVideoList()
                console.log(`当前页: ${val}`);
            },
            handleShow(row, data) {
                // console.log(data)
                if (!data.id) {
                    return false;
                }
                // window.open(data.download_url)
                // this.handleToDow(data)
                // this.$router.push({ name: 'videoShow' ,query:{id:data.id}})
            },
            handleEdit(row, data) {
                this.$router.push({name: 'videoOperate', query: {id: data.id}})
            },
            handleDelete(row, data) {
                this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    getUservideoDelete({ids: data.id}).then(res => {
                        this.$message.success(res.msg);
                        this.getVideoList()
                    }).catch(err => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            handleCopydata(row, data) {

                this.$confirm('此操作将复制该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    getCopydata({id: data.id}).then(res => {
                        this.$message.success(res.msg);
                        this.getVideoList()
                    }).catch(err => {
                        console.log(err)
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消复制'
                    });
                });

            },
            handleToDow(row, data) {
                if (!data.download_url) {
                    return false
                }

                this.$confirm('此操作将下载该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    getUservideoInfo({id: data.id}).then(res => {
                        this.$message.success(res.msg);
                        window.open(res.data.download_url)
                    }).catch(err => {
                        this.$message.error(err.msg);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消下载'
                    });
                });


                // 			let url = window.URL.createObjectURL(new Blob([data.download_url])) //创建下载链接
                // 			let link = document.createElement('a') //创建a标签
                // 			link.style.display = 'none'  //将a标签隐藏
                // 　　　　　	link.href = url  //给a标签添加下载链接
                // 			link.setAttribute('download', data.title+'.mp4') // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
                // 			document.body.appendChild(link)
                // 			link.click()  //执行a标签
            },
            getVideoList: function () {
                this.loading = true
                getUservideo(this.form).then(res => {
                    res.data.list.forEach((iten, index) => {
                        res.data.list[index].getTime = 1
                    })
                    this.tableData = res.data.list
                    console.log('视频列表', this.tableData)
                    this.total = res.data.count
                    this.loading = false
                    this.timer()
                }).catch(err => {
                    this.loading = false
                    console.log(err)
                })
            },
            ace() {
                getUservideo(this.form).then(ers => {
                    let dck = []
                    let status = ''
                    let dd = 0
                    dck = ers.data.list
                    console.log(this.tableData)
                    this.tableData.forEach((el, index) => {
                        /*status = el.status*/
                        /*this.yu[index] = {status}*/
                        /*this.yu.forEach((va,key)=>{*/
                        /*va.status = el.status*/
                        el.sc_time = Math.round(el.sc_time)
                        if (el.status == 1) {
                            el.currentTime = (new Date()).valueOf() / 1000;
                            dd = (el.currentTime - el.sc_time) / 6
                            el.getTime = Math.round(dd)
                            el.viewTime = formatDate(el.currentTime, true)
                            el.CTime = formatDate(el.sc_time, true)
                            if (el.getTime >= 99 ) {
                                el.getTime = 99
                            }
                            if (el.getTime <= 1){
                                el.getTime = 1
                            }
                        }
                    })
                    this.total = ers.data.count
                    console.log('计算时间', this.tableData, '当前时间',)
                }).catch(err => {
                    this.loading = false
                    console.log(err)
                })
            },
            timer() {
                setInterval(() => {
                    let dd = 0
                    console.log(this.tableData)
                    this.tableData.forEach((el, index) => {
                        el.sc_time = Math.round(el.sc_time)
                        if (el.status == 1) {
                            el.currentTime = (new Date()).valueOf() / 1000;
                            dd = (el.currentTime - el.sc_time) / 6
                            el.getTime = Math.round(dd)
                            el.viewTime = formatDate(el.currentTime, true)
                            el.CTime = formatDate(el.sc_time, true)
                            if (el.getTime >= 99 ) {
                                el.getTime = 99
                            }
                            if (el.getTime <= 1){
                                el.getTime = 1
                            }
                        }
                    })
                    console.log('计算时间', this.tableData, '当前时间',)
                }, 2000)
            }
        },

    }
</script>

<style lang="scss" scoped>
    .video {
        font-size: 28px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
    }

    .videobut {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
    }

    .el-table .cell img {
        display: block;
    }

    .pagination {
        margin-top: 16px;
        text-align: center;
    }

    .img-tip {
        position: relative;
        text-align: center;

        .jss596 {
            top: 0;
            right: 3px;
            height: 100%;
            display: flex;
            position: absolute;
            align-items: center;
            flex-direction: row;
            // svg {
            //     filter: drop-shadow(20px 0 0 #C4C6C6);
            //     position: relative;
            //     left: -21px;
            //     z-index: -1;
            // }
        }

        .img-background {
            transform: scale(0.65);
            border-radius: 12px;
        }
    }
</style>
