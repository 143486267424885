import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login'
import Index from '@/views/index'
import videoOperate from '@/views/officeDesk/videoOperate'
import DataModification from '@/views/HomeConsole/dataModification'
import indexHome from '@/views/HomeConsole/index'
import MaterialList from '@/views/HomeConsole/materialList'
import Recharge from '@/views/HomeConsole/recharge'
import VideoList from '@/views/HomeConsole/videoList'
import PerformerList from '@/views/HomeConsole/performerList'
import VideoShow from '@/views/HomeConsole/videoShow'

Vue.use(VueRouter)

const routes = [
	{ path: '/', redirect: '/index'},
	{
		path: '/index',
		meta:1,
		component: Index,
		children:[
			{path:'/index',name:"index",meta:2,component: indexHome},
			{path:'/dataModification',name:"dataModification",meta:2,component: DataModification},
			{path:'/materialList',name:"materialList",meta:2,component: MaterialList},
			{path:'/recharge',name:"recharge",meta:2,component: Recharge},
			{path:'/performerList',name:"performerList",meta:2,component: PerformerList},
			{path:'/videoList',name:"videoList",meta:2,component: VideoList},
			{path:'/videoShow',name:"videoShow",meta:2,component: VideoShow},
		]
	},
	{ path: '/login',name:"login", component: Login,meta:0},
	{ path: '/videoOperate',name:"videoOperate", component: videoOperate}
]
 
const router = new VueRouter({
  routes
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
