import axios from 'axios'
import router from '../router'
import store from '../store'
import { urlencode } from './commons'


// instance.interceptors.request.use(
//   config => {
//     // const token = sessionStorage.getItem('token')
//     // if (token ) { // 判断是否存在token，如果存在的话，则每个http header都加上token
//     //   config.headers.authorization = token  //请求头加上token
//     // }
//     return config
//   },
//   err => {
//     return Promise.reject(err)
//   })
  
  
// http response 拦截器
// instance.interceptors.response.use(
//   response => {
//     //拦截响应，做统一处理 
//     return response
//   },
//   //接口错误状态处理，也就是说无响应时的处理
//   error => {
//     return Promise.reject(error.response.status) // 返回接口返回的错误信息
//   })

export function requestApi (reqUrl, type = 'POST', data = {}, auth = '', multipart = false) {
  if (!reqUrl) { return }
  let headers = {}

  if (auth === 'member') {
	
	if (typeof (store.state.member.tokenTime) === 'number' || !store.state.member.tokenTime) {
		if(Date.parse(new Date()) > Number(store.state.member.tokenTime) + 86380000){
			router.push({name:'login',query:{clear: 1 }})
		}
	}
	
    if (typeof (store.state.member.token) === 'undefined' || !store.state.member.token) {
		router.push({ name: 'login' })
    }
	
    headers['Authorization'] = store.state.member.token
	
  }

  if (multipart) {
    headers['Content-Type'] = 'multipart/form-data'
  }
  type = type.toUpperCase()
  
  // reqUrl = env.API_HOST + '/api' + reqUrl
  reqUrl =  '/api' + reqUrl

  let axiosData
  if (type === 'POST') {
    axiosData = {
      url: reqUrl,
      data: data,
      method: type,
      headers: headers
    }
  } else {
    if (JSON.stringify(data) !== '{}') {
      reqUrl += '?' + urlencode(data)
    }
    axiosData = {
      url: reqUrl,
      method: type,
      headers: headers
    }
  }
  return new Promise((resolve, reject) => {
    // console.log(axiosData)
    axios(axiosData).then(res => {
		// console.log(res)
      if (Number(res.data.status) === 200) {
        resolve(res.data)
      } else if (Number(res.data.status) === 101) {
        router.push({ name: 'login', query: { clear: 1 } })// token过期，需要删除
      } else if (Number(res.data.status) === 13001) {
        router.push({ name: 'login', query: { clear: 1 } })// token过期，需要删除
      } else {
        reject(res.data)
      }
    }, error => {
		// console.log(typeof(error))
		var data={
			msg:typeof(error) === 'string'?error:'网络出现故障，请稍后重试!'
		}
		reject(data)
    })
  })
}


export function requestRaw (reqUrl, type = 'POST', data = {}) {
  type = type.toUpperCase()
  reqUrl =  '/api' + reqUrl
  let axiosData
  if (type === 'POST') {
    axiosData = {
      method: type,
      url: reqUrl,
      data: data
    }
  } else {
    if (JSON.stringify(data) !== '{}') {
      reqUrl += '?' + urlencode(data)
    }
    axiosData = {
      method: type,
      url: reqUrl
    }
  }
  return new Promise((resolve, reject) => {
    axios(axiosData).then(res => {
		console.log(res)
      resolve(res.data)
    }, error => {
      reject(error)
    })
  })
}
